import {AsyncContainerModule, interfaces} from "inversify";
import {chatTypes} from "./types";
import {
  RoomService,
  MessageService,
  ApiMessageService,
  ApiRoomService,
  MessageUseCase,
  RoomUseCase
} from "../features/text";
import {
  MeetingService,
  ApiMeetingService,
  MeetingUseCase,
  VideoTransportServiceFactory,
} from "../features/video";
import {NuxtVideoTransportServiceFactory} from "../features/video/infrastructure/nuxtVideoTransportServiceFactory";
import {ChatRoomSessionsUseCase} from "../features/sessions/application";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<MessageService>(chatTypes.MessageService).to(ApiMessageService);
  bind<RoomService>(chatTypes.RoomService).to(ApiRoomService);
  bind<MeetingService>(chatTypes.MeetingService).to(ApiMeetingService);
  bind<VideoTransportServiceFactory>(chatTypes.VideoTransportServiceFactory).to(NuxtVideoTransportServiceFactory).inSingletonScope();
  bind<RoomUseCase>(chatTypes.RoomUseCase).to(RoomUseCase);
  bind<MessageUseCase>(chatTypes.MessageUseCase).to(MessageUseCase);
  bind<MeetingUseCase>(chatTypes.MeetingUseCase).to(MeetingUseCase);
  bind<ChatRoomSessionsUseCase>(chatTypes.SessionsUseCase).to(ChatRoomSessionsUseCase);
});
