import {CustomerRegistrationService} from "../domain";
import {injectable, inject} from "inversify";
import {ProfileRegistrationResponse} from "../../../models/registration";
import {Result} from "neverthrow";
import { httpTypes } from "@meclee/http/di/types";
import { HttpService } from "@meclee/http";
import {RuntimeError} from "@meclee/contracts";

@injectable()
export class ApiCustomerRegistrationService implements CustomerRegistrationService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async registerByFlow(fingerprint: string, flowId: string): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.put<ProfileRegistrationResponse>(`/id/registration/flows/${flowId}/profiles/customer`, {
      fingerprint,
    }, {});
  }

  async createProfile(fingerprint: string): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.put<ProfileRegistrationResponse>(`/id/profiles/customer`, {
      fingerprint,
    }, {});
  }
}
