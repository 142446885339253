import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {ok, Result} from "neverthrow";
import 'reflect-metadata';
import {Message} from "../../../models/message";
import {MessageService} from "../domain";

@injectable()
export class ApiMessageService implements MessageService {
    constructor(
        @inject(httpTypes.HttpService) private readonly httpService: HttpService,
    ) { }

    async fetchMessagesByRoomId(roomId: string): Promise<Result<Message[], RuntimeError>> {
        return await this.httpService.get<Message[]>(`chats/${roomId}/messages`, {}, {});
    }

    async sendTextMessage(roomId: string, text: string): Promise<Result<Message, RuntimeError>> {
        return await this.httpService.post<Message>(`chats/${roomId}/messages/sendText`, {
            text: text,
        }, {});
    }

    async readMessage(roomId: string, messageId: string): Promise<Result<void, RuntimeError>> {
        return await this.httpService.post<void>(`chats/${roomId}/messages/${messageId}/read`, {}, {});
    }
}
