import {inject, injectable} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {financeTypes} from "../../../di/types";
import {PayoutRequestRepository} from "../ports";

@injectable()
export class CreatePayoutRequest {
  constructor(
    @inject(financeTypes.PayoutRequestRepository) private readonly payoutRequestRepository: PayoutRequestRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async execute(payoutMethodId: string, creditTransactionIds: string[]): Promise<{file: Blob, name: string}|void> {
    const response = await this.payoutRequestRepository.create(payoutMethodId, creditTransactionIds);

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }

    if (response.isOk()) {
      return response.value;
    }
  }
}
