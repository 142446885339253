import {AuthService} from "../domain";
import {FetchError, ofetch} from "ofetch";
import {injectable, inject} from "inversify";
import 'reflect-metadata';
import {LoginFlowType} from "../enums";
import {LoginFlow, UserNotFoundError, AuthInternalError} from "../../../models/login";
import {Result, ok, err} from "neverthrow";
import {RuntimeError, TranslatableMessage} from "@meclee/contracts";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {OauthProvider} from "../enums/oauthProvider";

@injectable()
export class NuxtAuthService implements AuthService {
  constructor(
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getAccessToken(): Promise<string> {
      return await ofetch<string>('/api/token');
  }

  async createEmailPasswordLoginFlow(fingerprint: string): Promise<Result<LoginFlow, RuntimeError>> {
    try {
      const response = await ofetch<LoginFlow>('/api/auth/login/createLoginFlow', {
        method: 'PUT',
        body: {
          fingerprint,
          type: LoginFlowType.EMAIL,
        }
      });
      return ok(response);
    } catch (error) {
      return err(error);
    }
  }

  async createPasswordlessLoginFlow(fingerprint: string, email: string): Promise<Result<LoginFlow, RuntimeError>> {
    try {
      const response = await ofetch<LoginFlow>('/api/auth/login/createLoginFlow', {
        method: 'PUT',
        body: {
          fingerprint,
          email,
          type: LoginFlowType.PASSWORDLESS,
        }
      });
      return ok(response);
    } catch (error) {
      return err(error);
    }
  }

  async createOAuthLoginFlow(fingerprint: string, provider: OauthProvider): Promise<Result<LoginFlow, RuntimeError>> {
    try {
      const response = await ofetch<LoginFlow>('/api/auth/login/createLoginFlow', {
        method: 'PUT',
        body: {
          fingerprint,
          type: LoginFlowType.OAUTH,
          provider,
        }
      });
      return ok(response);
    } catch (error) {
      return err(error);
    }
  }



  async loginByEmailPassword(flowId: string, email: string, password: string): Promise<Result<void, RuntimeError>> {
    try {
      await ofetch('/api/auth/login/byEmailPassword', {
        method: 'POST',
        body: {
          flowId,
          email,
          password,
        }
      });
      return ok(undefined);
    } catch (error) {
      if (error instanceof FetchError) {
        if (error.statusCode === 422) {
          this.notifierService.notifyFromHttpError(new UserNotFoundError());
        } else {
          this.notifierService.notifyFromHttpError(new AuthInternalError());
        }
      }

      return err(error);
    }
  }

  async loginByMagicToken(flowId: string, token: string, fingerprint: string): Promise<Result<void, RuntimeError>> {
    try {
      await ofetch('/api/auth/login/byMagicToken', {
        method: 'POST',
        body: {
          flowId,
          token,
          fingerprint,
        }
      });
      return ok(undefined);
    } catch (error) {
      if (error instanceof FetchError) {
        this.notifierService.notifyFromHttpError(new AuthInternalError());
      }

      return err(error);
    }
  }

  async loginByOAuthCode(flowId: string, fingerprint: string, code: string): Promise<Result<void, RuntimeError>> {
    try {
      await ofetch('/api/auth/login/byOAuthCode', {
        method: 'POST',
        body: {
          flowId,
          fingerprint,
          code,
        }
      });
      return ok(undefined);
    } catch (error) {
      if (error instanceof FetchError) {
        this.notifierService.notifyFromHttpError(new AuthInternalError());
      }

      return err(error);
    }
  }

  async refreshToken(): Promise<Result<void, RuntimeError>> {
    try {
      await ofetch('/api/auth/refreshSession', {method: 'POST'});
      return ok(undefined);
    } catch (error) {
      return err(error);
    }
  }

  async switchProfile(profileId: string): Promise<Result<void, RuntimeError>> {
    try {
      await ofetch('/api/auth/switchProfile', {method: 'POST', body: {profileId}, credentials: 'same-origin'});
      return ok(undefined);
    } catch (error) {
      if (error instanceof FetchError) {
        await this.refreshToken();
        await ofetch('/api/auth/switchProfile', {method: 'POST', body: {profileId}, credentials: 'same-origin'});
      } else {
        return err(error);
      }
    }
  }

  async logout(): Promise<Result<void, RuntimeError>> {
      try {
        await ofetch('/api/auth/logout', {method: 'DELETE'});
        return ok(undefined);
      } catch (error) {
        return err(error);
      }
  }

  async createResetPasswordRequest(fingerprint: string, email: string): Promise<Result<void, RuntimeError>> {
    try {
      await ofetch('/api/auth/createResetPassword', {
        method: 'POST',
        body: {
          fingerprint,
          email,
        }
      });
      return ok(undefined);
    } catch (error) {
      if (error instanceof FetchError) {
        this.notifierService.notifyFromHttpError(this.mapError(error));
      }

      return err(error);
    }
  }

  async confirmResetPasswordRequest(flowId: string, fingerprint: string, password: string): Promise<Result<void, RuntimeError>> {
    try {
      await ofetch('/api/auth/confirmResetPassword', {
        method: 'POST',
        body: {
          flowId,
          fingerprint,
          password,
        }
      });
      return ok(undefined);
    } catch (error) {
      if (error instanceof FetchError) {
        this.notifierService.notifyFromHttpError(this.mapError(error));
      }

      return err(error);
    }
  }

  private mapError(error: FetchError) {
    if (error.statusCode === 422) {
      return new UnprocessableContentError(error.data.variables, error.data.message);
    } else {
      return new ServerError();
    }
  }
}


class ServerError implements RuntimeError {
  public readonly statusCode: number = 500;

  constructor(
    private readonly variables: any[] = []
  ) { }

  getMessage(): TranslatableMessage {
    return {message: "http.errors.500", variables: this.variables};
  }
}

class UnprocessableContentError implements RuntimeError {
  public readonly statusCode: number = 422;

  constructor(
    private readonly variables: Record<string, string>,
    private readonly errorMessage: string|undefined,
  ) { }

  getMessage(): TranslatableMessage {
    return {message: this.errorMessage ?? "http.errors.422", variables: this.variables};
  }
}
