import {RouteLocationNormalized} from "vue-router";
import {defineNuxtRouteMiddleware} from '#app';

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  if (process.server) {
    return null;
  }
  const {fetchSession, session} = useAuthSession();

  try {
    await fetchSession();
  } catch (e) { }

  const selectedProfile = session.value?.selectedProfile;
  if (!selectedProfile) {
    return navigateTo({path: `/cabinet/auth/sign-in`});
  }

  if (selectedProfile.type === 'employee' && !selectedProfile.metadata.isActivated) {
    return navigateTo({path: `/cabinet/employee/activation`});
  }
})
