import {EmployeeRegistrationService} from "../domain";
import {injectable, inject} from "inversify";
import {ProfileRegistrationResponse} from "../../../models/registration";
import {Result} from "neverthrow";
import { httpTypes } from "@meclee/http/di/types";
import { HttpService } from "@meclee/http";
import {RuntimeError} from "@meclee/contracts";

@injectable()
export class ApiEmployeeRegistrationService implements EmployeeRegistrationService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async confirmEmailByFlowId(flowId:string, profileId: string, code:string, fingerprint: string): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.post<ProfileRegistrationResponse>(`/id/registration/flows/${flowId}/profiles/employee/${profileId}/confirm`, {
      code, fingerprint,
    }, {});
  }

  async resendEmailByFlowId(flowId:string, profileId: string): Promise<Result<void, RuntimeError>> {
    return this.httpService.post<void>(`/id/registration/flows/${flowId}/profiles/employee/${profileId}/resend`, {}, {});
  }

  async registerByEmailAndFlow(flowId: string, corporateEmail: string, problemDescription: string, requests: string[]): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.put<ProfileRegistrationResponse>(`/id/registration/flows/${flowId}/profiles/employee`, {
      type: 'email',
      corporateEmail,
      problemDescription,
      requests,
    }, {});
  }

  async registerByInviteCodeAndFlow(fingerprint: string, flowId: string, inviteCode: string, problemDescription: string, requests: string[]): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.put<ProfileRegistrationResponse>(`/id/registration/flows/${flowId}/profiles/employee`, {
      type: 'code',
      inviteCode,
      problemDescription,
      requests,
      fingerprint,
    }, {});
  }

  async createByCorporateEmail(corporateEmail: string, problemDescription: string, requests: string[]): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.put<ProfileRegistrationResponse>(`/id/profiles/employee`, {
      type: 'email',
      corporateEmail,
      problemDescription,
      requests,
    }, {});
  }

  async createByInviteCode(fingerprint: string, inviteCode: string, problemDescription: string, requests: string[]): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.put<ProfileRegistrationResponse>(`/id/profiles/employee`, {
      type: 'code',
      inviteCode,
      problemDescription,
      requests,
      fingerprint,
    }, {});
  }

  async changeEmail(profileId: string, newEmail: string): Promise<Result<void, RuntimeError>> {
    return this.httpService.post<void>(`/id/profiles/employee/${profileId}/change-email`, {
      email: newEmail,
    }, {});
  }

  async confirmEmailByProfileId(profileId: string, code:string, fingerprint: string): Promise<Result<ProfileRegistrationResponse, RuntimeError>> {
    return this.httpService.post<ProfileRegistrationResponse>(`/id/profiles/employee/${profileId}/confirm`, {
      code, fingerprint,
    }, {});
  }

  async resendEmailByProfileId(profileId: string): Promise<Result<void, RuntimeError>> {
    return this.httpService.post<void>(`/id/profiles/employee/${profileId}/resend`, {}, {});
  }
}
