import {Container} from "inversify";
import {RealtimeService} from "../../../domain";
import {useOnlineStatus} from "../composables/useOnlineStatus";
import {realtimeTypes} from "../../../di/types";

export default defineNuxtPlugin((nuxtApp ) => {
    // @ts-ignore
  const container: Container = nuxtApp.$container;
    const realtimeService = container.get<RealtimeService>(realtimeTypes.RealtimeService);

    nuxtApp.hook('app:mounted', async () => {
      try {
        realtimeService.connect();
        const {addProfiles, removeProfile} = useOnlineStatus();
        realtimeService.subscribe('presence-meclee', 'pusher:subscription_succeeded', (members: any) => {
          Object.values(members.members).forEach((member: any) => {
            addProfiles(member.profile, member.participants);
          });
        });
        realtimeService.subscribe('presence-meclee', 'pusher:member_added', (member: any) => {
          addProfiles(member.profile, member.participants);
        });
        realtimeService.subscribe('presence-meclee', 'pusher:member_removed', (member: any) => {
          removeProfile(member.profile);
        });
      } catch (e) {
        // console.error(e);
      }
    });

    return {
        provide: {
            realtime: {
                subscribe: (channel: string, event: string, callback: Function) => {
                    return realtimeService.subscribe(channel, event, callback);
                },
                unsubscribe: (channel: string) => {
                    return realtimeService.unsubscribe(channel);
                },
            },
        }
    }
});
