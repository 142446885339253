import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Result} from "neverthrow";
import {PayoutMethodRepository} from "../ports";
import {PayoutMethod} from "../../../entities/payout";

@injectable()
export class ApiPayoutMethodRepository implements PayoutMethodRepository {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async createPayoutMethod(entity: PayoutMethod): Promise<Result<PayoutMethod, RuntimeError>> {
    return await this.httpService.put<PayoutMethod>(`finance/payout-methods/types/${entity.type}`, entity, {});
  }

  async deletePayoutMethod(payoutMethodId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.delete<void>(`finance/payout-methods/items/${payoutMethodId}`, {});
  }

  async getAllPayoutMethods(): Promise<Result<PayoutMethod[], RuntimeError>> {
    return await this.httpService.get<PayoutMethod[]>(`finance/payout-methods/items`, {}, {});
  }

  async getPayoutMethod(payoutMethodId: string): Promise<Result<PayoutMethod, RuntimeError>> {
    return await this.httpService.get<PayoutMethod>(`finance/payout-methods/items/${payoutMethodId}`, {}, {});
  }

  async updatePayoutMethod(entity: PayoutMethod): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`finance/payout-methods/items/${entity.id}`, entity, {});
  }
}
