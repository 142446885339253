import {Container} from "inversify";
import cabinetPsychologist from '@meclee/cabinet-psychologist/di/nuxtContainer';
import cabinetCustomer from '@meclee/cabinet-customer/di/nuxtContainer';
import content from '@meclee/content/di/nuxtContainer';
import seo from '@meclee/seo/di/nuxtContainer';
import {httpTypes} from "@meclee/http/di/types";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  /** @ts-ignore */
  const {$container} : {$container: Container} = nuxtApp;
  if (process.client) {
    $container.bind<string>(httpTypes.ApiUrl).toConstantValue(`${config.public.url}/api/mysweetyapi`);
  } else {
    $container.bind<string>(httpTypes.ApiUrl).toConstantValue(`${config.apiUrl}`);
  }
  $container.load(content);
  $container.load(cabinetPsychologist);
  $container.load(cabinetCustomer);
  $container.load(seo);
})
