import {injectable, inject} from "inversify";
import {cabinetPsychologistTypes} from "../../../di/types";
import {AccountService} from "../domain";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {BalanceResponse} from "../../../models/balance";

@injectable()
export class BalanceUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.AccountService) private readonly accountService: AccountService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getBalancePsychologist(date: string): Promise<BalanceResponse[]> {
    const balanceResponse = await this.accountService.getPsychologistBalance(date);
    if (balanceResponse.isOk()) {
      return balanceResponse.value;
    } else {
      this.notifierService.notifyFromHttpError(balanceResponse.error);
      throw balanceResponse.error;
    }
  }

  async getTransactions(accountId: string, year: number, month: number, timeZone: string): Promise<Transaction[]> {
    const transactionsResponse = await this.accountService.getTransactions(
      accountId,
      year,
      month,
      timeZone
    );
    if (transactionsResponse.isOk()) {
      return transactionsResponse.value;
    } else {
      this.notifierService.notifyFromHttpError(transactionsResponse.error);
      throw transactionsResponse.error;
    }
  }
}
