import revive_payload_client_x57qcjJvDs from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._fdzykjkkxl37teo3ggb76ncf5m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GVVBzmgywn from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._fdzykjkkxl37teo3ggb76ncf5m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_so50mIjOrL from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._fdzykjkkxl37teo3ggb76ncf5m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import posthog_client_XoPgTmZYDg from "/opt/apps/.build/node_modules/.pnpm/nuxt-posthog@1.5.0_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/posthog.client.mjs";
import _0_siteConfig_wxkQ83q4fL from "/opt/apps/.build/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.6.0_rollup@4.21.0_vite@5.4.5_@types+node@20.16.5_sass_m6htclu6purrxvw5lkqkf6mqqe/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import eventbus_W3yKBUUbOO from "/opt/apps/.build/packages/nuxt/layers/core/plugins/eventbus.ts";
import payload_client_ZCNJhHObO0 from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._fdzykjkkxl37teo3ggb76ncf5m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HDMLlZZAhh from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._fdzykjkkxl37teo3ggb76ncf5m/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_khOwJPk54o from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._fdzykjkkxl37teo3ggb76ncf5m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8tdVj9jG2M from "/opt/apps/.build/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.5_rollup@4.21.0_typescript@5.5.4_vue@3.5.5_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/apps/.build/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_NgR4TAhOYy from "/opt/apps/.build/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._fdzykjkkxl37teo3ggb76ncf5m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_biPzugEk4D from "/opt/apps/.build/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_ziYcKwnJdq from "/opt/apps/.build/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_wMUvPRMaXu from "/opt/apps/.build/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.20.0_vue@3.5.5_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_M53ydqHO9b from "/opt/apps/.build/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.20.0_vue@3.5.5_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import container_client_nVWA1Y6TnX from "/opt/apps/.build/packages/nuxt/app/plugins/container.client.ts";
import splide_b8AOuWtdaN from "/opt/apps/.build/packages/nuxt/modules/shared/nuxt/plugins/splide.ts";
import i18n_9rrtpUksQb from "/opt/apps/.build/packages/nuxt/modules/shared/nuxt/plugins/i18n.ts";
import directives_QUK4YVWtOf from "/opt/apps/.build/node_modules/.pnpm/nuxt-posthog@1.5.0_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-posthog/dist/runtime/plugins/directives.mjs";
import nuxt_plugin_jGyTF9EUJX from "/opt/apps/.build/node_modules/.pnpm/nuxt-delay-hydration@1.3.6_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_1I2epbPI1H from "/opt/apps/.build/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_oO3c8KUVBy from "/opt/apps/.build/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node_gs465fje53wrikdfqiw4j6ghhu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_GGQagwJd2Z from "/opt/apps/.build/node_modules/.pnpm/nuxt-gtag@1.2.1_magicast@0.3.5_rollup@4.21.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_loQeW1khFY from "/opt/apps/.build/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.6.0_rollup@4.21.0_vite@5.4.5_@types+node@20.16.5_sass_m6htclu6purrxvw5lkqkf6mqqe/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import realtime_e9I2iuf1QN from "/opt/apps/.build/packages/realtime/frameworks/nuxt/plugins/realtime.ts";
import vue_final_modal_Qge1y8PDAA from "/opt/apps/.build/packages/mosaic-ui/plugins/vue-final-modal.ts";
import chat_client_QnolXMNF5e from "/opt/apps/.build/packages/nuxt/layers/chat/plugins/chat.client.ts";
import cabinet_employee_client_rDBJOTl467 from "/opt/apps/.build/packages/nuxt/layers/employee/plugins/cabinet-employee.client.ts";
import auth_client_ja3bg81aM7 from "/opt/apps/.build/packages/nuxt/layers/auth/plugins/auth.client.ts";
import chartjs_client_dSP4DDlQKI from "/opt/apps/.build/packages/nuxt/app/plugins/chartjs.client.ts";
import tippy_Tm6mNIN2oH from "/opt/apps/.build/packages/nuxt/app/plugins/tippy.ts";
import vue_sticky_2bKXDstOpf from "/opt/apps/.build/packages/nuxt/app/plugins/vue-sticky.ts";
import vue_loading_overlay_client_VSHfrAParA from "/opt/apps/.build/packages/nuxt/app/plugins/vue-loading-overlay.client.ts";
import vue_toastification_client_maBAOfUNg5 from "/opt/apps/.build/packages/nuxt/app/plugins/vue-toastification.client.ts";
import fingerprint_client_e9LjqsM7zD from "/opt/apps/.build/packages/nuxt/app/plugins/fingerprint.client.ts";
import persistedstate_client_bEMpAXRUa9 from "/opt/apps/.build/packages/nuxt/app/plugins/persistedstate.client.ts";
import container_G9eXPTg2zh from "/opt/apps/.build/apps/web/app/plugins/container.ts";
import hotjar_client_Cy6i7zwihw from "/opt/apps/.build/apps/web/app/plugins/hotjar.client.ts";
import seo_vqPCsNMqnx from "/opt/apps/.build/apps/web/layers/seo/plugins/seo.ts";
export default [
  revive_payload_client_x57qcjJvDs,
  unhead_GVVBzmgywn,
  router_so50mIjOrL,
  posthog_client_XoPgTmZYDg,
  _0_siteConfig_wxkQ83q4fL,
  eventbus_W3yKBUUbOO,
  payload_client_ZCNJhHObO0,
  navigation_repaint_client_HDMLlZZAhh,
  chunk_reload_client_khOwJPk54o,
  plugin_vue3_8tdVj9jG2M,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NgR4TAhOYy,
  plugin_biPzugEk4D,
  plugin_ziYcKwnJdq,
  switch_locale_path_ssr_wMUvPRMaXu,
  i18n_M53ydqHO9b,
  container_client_nVWA1Y6TnX,
  splide_b8AOuWtdaN,
  i18n_9rrtpUksQb,
  directives_QUK4YVWtOf,
  nuxt_plugin_jGyTF9EUJX,
  plugin_1I2epbPI1H,
  plugin_oO3c8KUVBy,
  plugin_client_GGQagwJd2Z,
  i18n_loQeW1khFY,
  realtime_e9I2iuf1QN,
  vue_final_modal_Qge1y8PDAA,
  chat_client_QnolXMNF5e,
  cabinet_employee_client_rDBJOTl467,
  auth_client_ja3bg81aM7,
  chartjs_client_dSP4DDlQKI,
  tippy_Tm6mNIN2oH,
  vue_sticky_2bKXDstOpf,
  vue_loading_overlay_client_VSHfrAParA,
  vue_toastification_client_maBAOfUNg5,
  fingerprint_client_e9LjqsM7zD,
  persistedstate_client_bEMpAXRUa9,
  container_G9eXPTg2zh,
  hotjar_client_Cy6i7zwihw,
  seo_vqPCsNMqnx
]