export const chatTypes = {
  RoomService: Symbol.for('ChatRoomService'),
  MessageService: Symbol.for('ChatMessageService'),
  MeetingService: Symbol.for('ChatMeetingService'),
  VideoTransportService: Symbol.for('ChatVideoTransportService'),
  VideoTransportServiceFactory: Symbol.for('ChatVideoTransportServiceFactory'),
  MessageUseCase: Symbol.for('ChatMessageUseCase'),
  RoomUseCase: Symbol.for('ChatRoomUseCase'),
  MeetingUseCase: Symbol.for('ChatMeetingUseCase'),
  SessionsUseCase: Symbol.for('ChatSessionsUseCase'),
}
