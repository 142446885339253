import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {contentTypes} from "../../../di/types";
import {CategoriesService} from "../domain";
import {Category} from "../../../models/blog";

@injectable()
export class GetCategoriesUseCase {
  constructor(
    @inject(contentTypes.CategoriesService) private readonly categoriesService: CategoriesService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getAllCategories(): Promise<Category[]> {
    const response = await this.categoriesService.getAllCategories();
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
