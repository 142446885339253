import {inject, injectable} from "inversify";
import {ProfileRepository} from "../domain";
import {HttpService} from '@meclee/http';
import { httpTypes } from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {GeneralInfo, PaymentMethodResponse, Service} from "../../../models/profile";
import {RuntimeError} from "@meclee/contracts";

@injectable()
export class ApiProfileRepository implements ProfileRepository {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getGeneralInfo(id: string): Promise<Result<GeneralInfo, RuntimeError>> {
    return await this.httpService.get<GeneralInfo>(`profile/psychologists/${id}/general`, {}, {});
  }

  async updateGeneralInfo(id: string, generalInfo: GeneralInfo): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`profile/psychologists/${id}/general`, generalInfo, {});
  }

  async getServices(id: string): Promise<Result<Service[], RuntimeError>> {
    return await this.httpService.get<Service[]>(`profile/psychologists/${id}/services`, {}, {});
  }

  async updateServices(id: string, services: Service[]): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`profile/psychologists/${id}/services`, services, {});
  }

  async getEnabledPaymentMethods(id: string): Promise<Result<PaymentMethodResponse[], RuntimeError>> {
    return await this.httpService.get<PaymentMethodResponse[]>(`profile/psychologists/${id}/paymentMethods`, {}, {});
  }

  async deletePaymentMethod(profileId: string, paymentMethodId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.delete<void>(`profile/psychologists/${profileId}/paymentMethods/${paymentMethodId}`, {}, {});
  }
}
