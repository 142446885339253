import {inject, injectable} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {financeTypes} from "../../../di/types";
import {PayoutMethodRepository} from "../ports";
import {BankTransferPayoutMethod, FondyMerchantPayoutMethod, P2PPayoutMethod} from "../../../entities/payout";
import {PayoutMethodType} from "../../../enums";

@injectable()
export class CreatePayoutMethod {
  constructor(
    @inject(financeTypes.PayoutMethodRepository) private readonly payoutMethodRepository: PayoutMethodRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async createP2PMethod(tin: string, card: string): Promise<void> {
    const entity: P2PPayoutMethod = {tin, card, type: PayoutMethodType.P2P} as P2PPayoutMethod
    const response = await this.payoutMethodRepository.createPayoutMethod(entity);

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async createFondyMerchantMethod(merchantId: string): Promise<void> {
    const entity: FondyMerchantPayoutMethod = {merchantId, type: PayoutMethodType.FondyMerchant} as FondyMerchantPayoutMethod;
    const response = await this.payoutMethodRepository.createPayoutMethod(entity);

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async createBankTransferMethod(
    tin: string, iban: string, phoneNumber: string,
    legalEntityName: string, legalEntityAddress: string, legalEntityBankName: string
  ): Promise<void> {
    const entity: BankTransferPayoutMethod = {
      type: PayoutMethodType.BankTransfer,
      tin, iban, phoneNumber, legalEntityName, legalEntityAddress, legalEntityBankName
    } as BankTransferPayoutMethod;
    const response = await this.payoutMethodRepository.createPayoutMethod(entity);

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
