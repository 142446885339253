import {RegistrationService} from "../domain";
import {injectable, inject} from "inversify";
import {RegistrationFlow} from "../../../models/registration";
import {Result} from "neverthrow";
import { httpTypes } from "@meclee/http/di/types";
import { HttpService } from "@meclee/http";
import {RuntimeError} from "@meclee/contracts";

@injectable()
export class ApiRegistrationService implements RegistrationService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async createRegisterFlow(fingerprint: string, locale: string, email: string, password: string, name: string): Promise<Result<RegistrationFlow, RuntimeError>> {
    return this.httpService.put<RegistrationFlow>(`/id/registration/flows`, {
      fingerprint, locale, email, password, name,
    }, {});
  }

  async confirmEmail(flowId:string, code:string): Promise<Result<void, RuntimeError>> {
    return this.httpService.post<void>(`/id/registration/flows/${flowId}/confirm`, {
      code
    }, {});
  }

  async confirmEmailByRequestId(requestId:string, token:string): Promise<Result<void, RuntimeError>> {
    return this.httpService.post<void>(`/id/registration/email-verifications/${requestId}/confirm`, {
      code: token
    }, {});
  }

  async resendEmail(flowId:string): Promise<Result<void, RuntimeError>> {
    return this.httpService.post<void>(`/id/registration/flows/${flowId}/resend`, {}, {});
  }
}
