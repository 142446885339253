import * as Fingerprint from '@fingerprintjs/fingerprintjs/dist/fp.esm';

export default defineNuxtPlugin(async (nuxtApp) => {
  const fp = await Fingerprint.load({
    monitoring: false,
  });
  const fingerprint = await fp.get();

  return {
    provide: {
      visitorId: fingerprint.visitorId,
    }
  }
});
