import {inject, injectable} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {financeTypes} from "../../../di/types";
import {PayoutMethodRepository} from "../ports";

@injectable()
export class UpdatePayoutMethod {
  constructor(
    @inject(financeTypes.PayoutMethodRepository) private readonly payoutMethodRepository: PayoutMethodRepository,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }
}
