import 'reflect-metadata';
import {injectable, inject} from "inversify";
import {i18nTypes} from "@meclee/i18n/di/types";
import {I18nService} from "@meclee/i18n";
import {NotifierService} from "../notifierService";

@injectable()
export class NuxtToastNotifierService implements NotifierService {
  constructor(
    @inject(i18nTypes.I18nService) private readonly i18nService: I18nService,
  ) { }

  notifyFromHttpError(error: any): void {
    const localizedMessage = this.i18nService.translate(error.getMessage().message, error.getMessage().variables);
    if (process.client) {
      const {$showError, $showWarning} = useNuxtApp();
      if (error.statusCode === 403) {
        $showWarning(localizedMessage);
      } else {
        $showError(localizedMessage);
      }
    } else {
      console.error(localizedMessage);
    }
  }

  notifyFromCustomError(error: string) {
    if (process.client) {
      const {$showError} = useNuxtApp();
      $showError(error);
    } else {
      console.error(error);
    }
  }

  createSuccessNotification(message: string, variables: Record<string, string>) {
    const localizedMessage = this.i18nService.translate(message, variables);
    if (process.client) {
    const {$showSuccess} = useNuxtApp();
      $showSuccess(localizedMessage);
    }
  }
}
