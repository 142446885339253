import {RealtimeService} from "../domain";
import Pusher from 'pusher-js/with-encryption';
import * as PusherTypes from 'pusher-js/with-encryption';
import {inject, injectable} from "inversify";
import {realtimeTypes} from "../di/types";

@injectable()
export class PusherJsService implements RealtimeService {
  private readonly pusherClient: Pusher;

  constructor(
    @inject(realtimeTypes.PusherAppKey) pusherAppKey: string,
    @inject(realtimeTypes.PusherHost) pusherHost: string,
    @inject(realtimeTypes.PusherMasterKey) pusherMasterKey: string,
  ) {
    this.pusherClient = new Pusher(pusherAppKey, {
      wsHost: pusherHost,
      forceTLS: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      cluster: '',
      encryptionMasterKeyBase64: pusherMasterKey,
      channelAuthorization: {
        endpoint: '/api/mysweetyapi/realtime/pusher/auth',
        transport: 'ajax',
      },
    } as PusherTypes.Options);
  }

  async connect(): Promise<void> {
    this.pusherClient.connect();
    return new Promise((resolve, reject) => {
      this.pusherClient.connection.bind('connected', () => {
        resolve();
      })
    });
  }

  disconnect() {
    this.pusherClient.disconnect();
  }

  subscribe(channelName: string, eventName: string, callback: Function): this {
    this.pusherClient.subscribe(channelName).bind(eventName, callback);
    return this;
  }

  unsubscribe(channelName: string): this {
    this.pusherClient.unsubscribe(channelName);
    return this;
  }

  emit(channelName: string, eventName: string, data: object): this {
    this.pusherClient.channel(channelName).trigger(eventName, data);
    return this;
  }
}
