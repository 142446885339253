import {AsyncContainerModule, interfaces} from "inversify";
import {seoTypes} from "./types";
import {ApiMetadataService} from "../features/metadata/infrastructure";
import {MetadataService} from "../features/metadata/domain";
import {GetMetadataUseCase} from "../features/metadata/application";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<MetadataService>(seoTypes.MetadataService).to(ApiMetadataService);

  bind<GetMetadataUseCase>(seoTypes.GetMetadataUseCase).to(GetMetadataUseCase);
});
