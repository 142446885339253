import {AsyncContainerModule, interfaces} from "inversify";
import {
  CreatePayoutMethod, CreatePayoutRequest, DeletePayoutMethod,
  GetPayoutMethod, GetPayoutRequests,
  PayoutMethodRepository,
  PayoutRequestRepository, UpdatePayoutMethod
} from "../features/payouts";
import {financeTypes} from "./types";
import {ApiPayoutMethodRepository} from "../features/payouts/adapters/apiPayoutMethodRepository";
import {BalanceRepository, GetBalances, GetTransactions} from "../features/balance";
import {ApiBalanceRepository} from "../features/balance/adapters/apiBalanceRepository";
import {ApiPayoutRequestRepository} from "../features/payouts/adapters/apiPayoutRequestRepository";
import {GetInvoiceUseCase} from "../features/invoices/application";
import {InvoiceService} from "../features/invoices/domain";
import {ApiInvoiceService} from "../features/invoices/infrastructure";
import {CreatePaymentMethodUseCase} from "../features/payments/application";
import {PaymentMethodService} from "../features/payments/domain";
import {ApiPaymentMethodService} from "../features/payments/infrastructure/apiPaymentMethodService";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<PayoutMethodRepository>(financeTypes.PayoutMethodRepository).to(ApiPayoutMethodRepository);
  bind<BalanceRepository>(financeTypes.BalanceRepository).to(ApiBalanceRepository);
  bind<PayoutRequestRepository>(financeTypes.PayoutRequestRepository).to(ApiPayoutRequestRepository);

  bind<CreatePayoutMethod>(financeTypes.CreatePayoutMethod).to(CreatePayoutMethod);
  bind<GetPayoutMethod>(financeTypes.GetPayoutMethod).to(GetPayoutMethod);
  bind<UpdatePayoutMethod>(financeTypes.UpdatePayoutMethod).to(UpdatePayoutMethod);
  bind<DeletePayoutMethod>(financeTypes.DeletePayoutMethod).to(DeletePayoutMethod);

  bind<GetBalances>(financeTypes.GetBalances).to(GetBalances);
  bind<GetTransactions>(financeTypes.GetTransactions).to(GetTransactions);
  bind<CreatePayoutRequest>(financeTypes.CreatePayoutRequest).to(CreatePayoutRequest);
  bind<GetPayoutRequests>(financeTypes.GetPayoutRequests).to(GetPayoutRequests);

  bind<InvoiceService>(financeTypes.InvoiceService).to(ApiInvoiceService);
  bind<GetInvoiceUseCase>(financeTypes.GetInvoiceUseCase).to(GetInvoiceUseCase);

  bind<CreatePaymentMethodUseCase>(financeTypes.CreatePaymentMethod).to(CreatePaymentMethodUseCase);
  bind<PaymentMethodService>(financeTypes.PaymentMethodService).to(ApiPaymentMethodService);
});
