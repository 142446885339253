import Twilio from "twilio-video";
import {inject, injectable} from "inversify";
import {MeetingState} from "../../../enums";
import {VideoTransportService} from "../domain";
import {eventBusTypes} from "@meclee/eventbus/di/types";
import {EventBus} from "@meclee/eventbus";
import {chatEvents} from "../../../index";
import {MeetingParticipant} from "../../../models/video";

@injectable()
export class TwilioVideoTransportService implements VideoTransportService {
    private _isCameraEnabled: boolean = false;
    private _isMicrophoneEnabled: boolean = true;
    private _isScreenShareEnabled: boolean = false;
    private _participants: { [key: string]: MeetingParticipant } = {};
    private room: Twilio.Room|undefined;
    private _meetingState: MeetingState = MeetingState.Disconnected;
    private _screenTrack: MediaStreamTrack|undefined = undefined;
    private videoDeviceId: string|null = null;
    private audioDeviceId: string = 'default';

    constructor(
        @inject(eventBusTypes.EventBus) private readonly eventBus: EventBus,
    ) { }

    async connect(meetingId: string, accessToken: string, name: string, videoDeviceId: string|null, audioDeviceId: string): Promise<void> {
        this._meetingState = MeetingState.Connecting;
        this.audioDeviceId = audioDeviceId;
        this.videoDeviceId = videoDeviceId;

        if (this.videoDeviceId) {
          this._isCameraEnabled = true;
        }

        const localTracks = await Twilio.createLocalTracks({
          // @ts-ignore
          audio: {deviceId: audioDeviceId},
          // @ts-ignore
          video: videoDeviceId === null ? false : {deviceId: videoDeviceId}
        })
        this.room = await Twilio.connect(accessToken, {
            name: meetingId,
            tracks: localTracks,
            // bandwidthProfile: {
            //   video: {
            //     mode: 'collaboration',
            //     dominantSpeakerPriority: 'high'
            //   }
            // },
            networkQuality: {
              local: 1,
              remote: 2
            }
        });
        this._participants[this.room.localParticipant.sid] = {
            name: this.room.localParticipant.identity,
            isLocal: true,
            audioTrack: undefined,
            videoTrack: undefined,
            networkQuality: 0,
        };
        this.room.localParticipant.on('networkQualityLevelChanged', (networkQualityLevel) => {
          this._participants[this.room?.localParticipant.sid as string].networkQuality = networkQualityLevel;
          this.eventBus.emit(chatEvents.LocalNetworkQualityChanged, null);
        });
        this.room.localParticipant.videoTracks.forEach((publication: Twilio.LocalTrackPublication) => {
          this._participants[this.room?.localParticipant.sid as string].videoTrack = publication.track.mediaStreamTrack;
        });

        this.room.participants.forEach((participant: Twilio.RemoteParticipant) => {
            this.handleConnectedRemoteParticipant(participant);
        });
        this.room.on('participantConnected', (participant: Twilio.RemoteParticipant) => {
            this.handleConnectedRemoteParticipant(participant);
        });
        this.room.on('participantDisconnected', (participant: Twilio.RemoteParticipant) => {
            delete this._participants[participant.sid];
            this.eventBus.emit(chatEvents.ParticipantDisconnected, null);
        });

        this._meetingState = MeetingState.Connected;
        this.eventBus.emit(chatEvents.Connected, null);
        return Promise.resolve(undefined);
    }

    disconnect(): Promise<void> {
        if (this._meetingState === MeetingState.Connected) {
            this.room?.localParticipant.videoTracks.forEach((publication: Twilio.LocalVideoTrackPublication) => {
              publication.track.stop();
            })
            this.room?.localParticipant.audioTracks.forEach((publication: Twilio.LocalAudioTrackPublication) => {
              publication.track.stop();
            })
            this.room?.disconnect();
            this._meetingState = MeetingState.Disconnected;
            this._participants = {};
            this.eventBus.emit(chatEvents.Disconnected, null);
        }
        return Promise.resolve(undefined);
    }

    private handleConnectedRemoteParticipant(participant: Twilio.RemoteParticipant) {
        this._participants[participant.sid] = {
            name: participant.identity,
            isLocal: false,
            audioTrack: undefined,
            videoTrack: undefined,
        };

        participant.on('trackSubscribed', (track: Twilio.RemoteTrack) => {
            if (track.kind === 'video') {
                this._participants[participant.sid].videoTrack = track.mediaStreamTrack;
            } else if (track.kind === 'audio') {
                this._participants[participant.sid].audioTrack = track.mediaStreamTrack;
            }

            this.eventBus.emit(chatEvents.RemoteTrackSubscribed, null);
        });
        participant.on('trackUnsubscribed', (track: Twilio.RemoteTrack) => {
            if (track.kind === 'video') {
                this._participants[participant.sid].videoTrack = undefined;
            } else if (track.kind === 'audio') {
                this._participants[participant.sid].audioTrack = undefined;
            }

            this.eventBus.emit(chatEvents.RemoteTrackUnsubscribed, null);
        });

        this.eventBus.emit(chatEvents.ParticipantConnected, null);
    }

    public get meetingState(): MeetingState {
        return this._meetingState;
    }

    public get participants(): MeetingParticipant[] {
        return Object.values(this._participants);
    }

    private publishCameraStream() {
      let options = {};
      if (this.videoDeviceId !== 'default') {
        options.deviceId = { exact: this.videoDeviceId };
      }
        Twilio.createLocalVideoTrack(options)
        .then((localVideoTrack: Twilio.LocalVideoTrack) => {
            return this.room?.localParticipant.publishTrack(localVideoTrack);
        })
        .then((publication: Twilio.LocalTrackPublication|undefined) => {
            this._participants[this.room?.localParticipant.sid as string].videoTrack = publication?.track.mediaStreamTrack;
            this.eventBus.emit(chatEvents.LocalCameraEnabled, null);
        });
    }

    public get isCameraEnabled(): boolean {
        return this._isCameraEnabled;
    }

    public set isCameraEnabled(isCameraEnabled: boolean) {
        if (this.meetingState === MeetingState.Connected) {
            if (!isCameraEnabled) {
                this.room?.localParticipant.videoTracks.forEach((publication: Twilio.LocalTrackPublication) => {
                    publication.unpublish();
                    this._participants[this.room?.localParticipant.sid as string].videoTrack = undefined;
                    this.eventBus.emit(chatEvents.LocalCameraDisabled, null);
                });
            } else {
                this.publishCameraStream();
            }
        }

        this._isCameraEnabled = isCameraEnabled;
    }

    public get isMicrophoneEnabled(): boolean {
        return this._isMicrophoneEnabled;
    }

    public set isMicrophoneEnabled(isMicrophoneEnabled: boolean) {
        if (this.meetingState === MeetingState.Connected) {
            if (!isMicrophoneEnabled) {
                this.room?.localParticipant.audioTracks.forEach((publication: Twilio.LocalTrackPublication) => {
                    publication.track.disable();
                    this.eventBus.emit(chatEvents.LocalMicrophoneDisabled, null);
                });
            } else {
                this.room?.localParticipant.audioTracks.forEach((publication: Twilio.LocalTrackPublication) => {
                    publication.track.enable();
                    this.eventBus.emit(chatEvents.LocalMicrophoneEnabled, null);
                });
            }
        }

        this._isMicrophoneEnabled = isMicrophoneEnabled;
    }

    public get isScreenShareEnabled(): boolean {
        return this._isScreenShareEnabled;
    }

    public set isScreenShareEnabled(isScreenShareEnabled: boolean) {
        if (this.meetingState === MeetingState.Connected) {
            if (!isScreenShareEnabled) {
                if (this._isCameraEnabled && this._screenTrack) {
                    this.room?.localParticipant.unpublishTrack(this._screenTrack);
                    this._screenTrack = undefined;
                    this._participants[this.room?.localParticipant.sid as string].videoTrack = undefined;
                    this.publishCameraStream();
                } else {
                    this._participants[this.room?.localParticipant.sid as string].videoTrack = undefined;
                }
            } else {
                navigator.mediaDevices.getDisplayMedia().then(stream => {
                    this._screenTrack = stream.getTracks()[0];
                    this.room?.localParticipant.publishTrack(new Twilio.LocalVideoTrack(this._screenTrack))
                        .then((publication: Twilio.LocalTrackPublication|undefined) => {
                            this._participants[this.room?.localParticipant.sid as string].videoTrack = publication?.track.mediaStreamTrack;
                            this.eventBus.emit(chatEvents.LocalCameraEnabled, null);
                        });
                })
            }
        }

        this._isScreenShareEnabled = isScreenShareEnabled;
    }
}
