import VueHotjar from "vue-hotjar-next";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (config.public.hotjar.id) {
    nuxtApp.vueApp.use(VueHotjar, {
      id: Number(config.public.hotjar.id),
      isProduction: config.public.hotjar.isProduction,
      snippetVersion: 6
    });
  }
});
