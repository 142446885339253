import {RuntimeError, TranslatableMessage} from "@meclee/contracts";

export type RegistrationFlow = {
  flowId: string,
}

export class InvalidVerificationCodeError implements RuntimeError {
    getMessage(): TranslatableMessage {
        return {
          message: 'auth.signUpForm.messages.verificationCodeInvalid',
          variables: [],
        }
    }

}

export enum EmployeeProfileActivationType {
  EMAIL = 'email',
  INVITE_CODE = 'code',
}

export enum ProfileRegistrationStatus {
  REGISTERED = 'registered',
  WAITING_FOR_CONFIRMATION = 'waiting_for_confirmation',
}

export type EmployeeProfileRegistration = {
  activationType: EmployeeProfileActivationType,
  corporateEmail: string|null,
  inviteCode: string|null,
  problemDescription: string,
  requests: any[],
}

export type ProfileRegistrationResponse = {
  id: string,
  status: ProfileRegistrationStatus,
  autoLoginData: {}|{flowId: string, metadata: Record<string, string>},
}
