import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import { TimeApiService } from "../domain/timeApiService";
import {ofetch} from 'ofetch';

@injectable()
export class ApiWorldTimeService implements TimeApiService {
  async fetchTimeByTz(tz: string): Promise<Result<string|number, any>> {
    try {
      const response = await ofetch(`//worldtimeapi.org/api/timezone/${tz}`);
      return response.utc_datetime;
    } catch (e) {
      return null
    }
  }
}
