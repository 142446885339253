import {marketplaceTypes} from "../../../di/types";
import {GoalsService} from "../domain/goalsService";
import {NotifierService} from "@meclee/notifications";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {injectable, inject} from "inversify";
import {
  CustomerGoalGroupHistoryItemResponse,
  CustomerGoalReportsResponse,
  CustomerGoalsResponse,
  CustomerGoalsResponseItem,
  CustomerWellbeingResponse
} from "../../../models/goals";
import {CustomerThoughtReportsResponse} from "../../../models/thoughts";

@injectable()
export class GoalsUseCase {
  constructor(
    @inject(marketplaceTypes.GoalsService) private readonly goalsService: GoalsService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getGoalsByCustomerId(customerId: string): Promise<CustomerGoalsResponse> {
    const response = await this.goalsService.getGoalsByCustomer(customerId);
    if (response.isOk()) {
      return response.value;
    }
  }

  async getGoalById(id: string): Promise<CustomerGoalsResponseItem> {
    const response = await this.goalsService.getGoalById(id);
    if (response.isOk()) {
      return response.value;
    }
  }

  async createGoalFromRequest(goalGroup: string, requestId: string, goal: number, progress: number): Promise<void> {
    const response = await this.goalsService.createGoal(goalGroup, requestId, goal, progress);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getReports(goalId: string): Promise<CustomerGoalReportsResponse> {
    const response = await this.goalsService.getReportsByGoalId(goalId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getGroupHistory(customerId: string, group: string): Promise<CustomerGoalGroupHistoryItemResponse> {
    const response = await this.goalsService.getReportsByGoalGroup(customerId, group);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async updateProgress(goalId: string, progress: number): Promise<void> {
    const response = await this.goalsService.updateValue(goalId, 'progress', progress);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
    if (response.isOk()) {
      this.notifierService.createSuccessNotification('marketplace.goals.goalUpdated');
    }
  }

  async updateGoalAmount(goalId: string, amount: number): Promise<void> {
    const response = await this.goalsService.updateValue(goalId, 'goal', amount);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
    if (response.isOk()) {
      this.notifierService.createSuccessNotification('marketplace.goals.goalUpdated');
    }
  }

  async getWellbeingChartData(customerId: string): Promise<CustomerWellbeingResponse> {
    const response = await this.goalsService.getWellbeingChartData(customerId);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
    if (response.isOk()) {
      return response.value;
    }
  }
}
