import {CardsService} from "../domain";
import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Card} from "../../../models/card";

@injectable()
export class ApiCardsService implements CardsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getFondyToken(): Promise<Result<{token: string}, RuntimeError>> {
    return await this.httpService.put<{token: string}>(`finance/cards/create/fondy`, {}, {});
  }

  async createLiqpayLink(): Promise<Result<{url: string}, RuntimeError>> {
    return await this.httpService.put<{url: string}>(`finance/cards/create/liqpay`, {}, {});
  }

  async createWayforpayLink(): Promise<Result<{url: string}, RuntimeError>> {
    return await this.httpService.put<{url: string}>(`finance/cards/create/wayforpay`, {}, {});
  }

  async getCards(): Promise<Result<Card[], RuntimeError>> {
    return await this.httpService.get<Card[]>(`finance/cards`, {}, {});
  }

  async deleteCard(cardId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.delete<{token: string}>(`finance/cards/${cardId}`, {}, {});
  }

  async setCardAsDefault(cardId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`finance/cards/${cardId}/default`, {}, {});
  }
}
