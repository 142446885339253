import {inject, injectable} from "inversify";
import {timeTypes} from "../../../di/types";
import {TimeApiService} from "../domain/timeApiService";
import {parseISO, differenceInMinutes} from 'date-fns';

@injectable()
export class CheckTimeUseCase {
  constructor(
    @inject(timeTypes.TimeApiService) private readonly timeApiService: TimeApiService,
  ) { }

  async check(timeZone: string): Promise<void> {
    let serverUtcTime;
    try {
      serverUtcTime = await this.timeApiService.fetchTimeByTz(timeZone);
    } catch (e) {
      return;
    }

    if (serverUtcTime) {
      const originalDateTime = parseISO(serverUtcTime);
      const userDateTime = new Date();
      const diffMinutes = differenceInMinutes(userDateTime, originalDateTime);
      if (diffMinutes > 1 || diffMinutes < -1) {
        throw {
          originalDateTime,
          userDateTime,
          diffMinutes,
          timeZone,
        }
      }
    }
  }
}
