import {injectable, inject} from 'inversify';
import {i18nTypes} from "@meclee/i18n/di/types";
import {I18nService} from "@meclee/i18n";
import {ValidationResult, ValidationSchema, ValidatorService} from "../index";
import {create, SuiteResult, test} from "vest";
import {rules} from "../validations";
import * as dot from 'dot-wild';

@injectable()
export class VestValidator implements ValidatorService {

  constructor(
    @inject(i18nTypes.I18nService) private readonly i18nService: I18nService,
  ) { }

  createTestFunction(schemaRules: any, fieldName: string, data: any) {
    schemaRules.forEach((rawRule: string|[string, object]) => {
      let rule, params;

      if (Array.isArray(rawRule)) {
        rule = rawRule[0];
        params = rawRule[1];
      } else {
        rule = rawRule;
        params = {};
      }

      const foundedRule = rules[rule];
      if (foundedRule) {
        test(fieldName, () => foundedRule.handler(dot.get(data, fieldName), params));
      }
    });
  }

  createTestSuite(schema: ValidationSchema, data: any, path: string|null = null) {
    for (const fieldName in schema) {
      const prefix = path === null ? '' : path+'.';
      const schemaRules = schema[fieldName];
      if (Array.isArray(schemaRules)) {
        this.createTestFunction(schemaRules, `${prefix}${fieldName}`, data);
      } else {
        for (const subFieldName in schema[fieldName]) {
          this.createTestSuite(schemaRules[subFieldName], data, `${prefix}${fieldName}.${subFieldName}`);
        }
      }
    }
  }

  async validate(schema: ValidationSchema, data: any): Promise<ValidationResult> {
    const suite = create((data = {}) => {
      this.createTestSuite(schema, data);
    });
    return new Promise((resolve, reject) => {
      const validationResult = new VestValidationResult(suite(data), this.i18nService);
      if (validationResult.isValid()) {
        resolve(validationResult);
      } else {
        reject(validationResult);
      }
    });
  }

}

class VestValidationResult implements ValidationResult {
  constructor(
    private readonly result: SuiteResult,
    private readonly i18nService: I18nService,
  ) { }

  getErrors(fieldName: string): string[] {
    return this.result.getErrors(fieldName);
  }

  getAllErrors(): {[key: string]: string[]} {
    const rawErrors = this.result.getErrors();
    let errors = {};
    for (let field in rawErrors) {
      if (!errors[field]) {
        errors[field] = [];
      }

      rawErrors[field].forEach(fieldError => {
        try {
          const messageObj = JSON.parse(fieldError);
          errors[field].push(this.i18nService.translate(messageObj.message, messageObj.params));
        } catch(e) {
          errors[field].push(this.i18nService.translate(fieldError));
        }
      });
    }
    return errors;
  }

  getWarnings(): Record<string, string[]> {
    return this.result.getWarnings();
  }

  isValid(): boolean {
    return this.result.valid;
  }
}
