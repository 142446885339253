import {injectable, inject} from "inversify";
import {cabinetPsychologistTypes} from "../../../di/types";
import {AnalyticsService} from "../domain";
import {PlannedSessionsResponse} from "../../../models/analytics";

@injectable()
export class AnalyticsUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.AnalyticsService) private readonly analyticsService: AnalyticsService,
  ) { }

  async getPlanned(profileId: string): Promise<PlannedSessionsResponse> {
    const response = await this.analyticsService.getPlannedData(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
