import {injectable, inject} from "inversify";
import {SessionRepository} from "../domain";
import {Session} from "../../../models/session";
import {ApiSessionRepository, DexieSessionRepository} from "./index";
import {authTypes} from "../../../di/types";

@injectable()
export class DexieApiSessionRepository implements SessionRepository {
  constructor(
    @inject(authTypes.ApiSessionRepository) private readonly apiSessionRepository: ApiSessionRepository,
    @inject(authTypes.DexieSessionRepository) private readonly dexieSessionRepository: DexieSessionRepository,
  ) { }

  async getSession(): Promise<Session|undefined> {
    let session = await this.dexieSessionRepository.getSession();
    if (!session) {
      session = await this.apiSessionRepository.getSession();
      if (session) {
        await this.dexieSessionRepository.persistSession(session);
      }
    }

    return session;
  }

}
