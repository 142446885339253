import {AsyncContainerModule, interfaces} from "inversify";
import {contentTypes} from "./types";
import {CategoriesService, PostsService} from "../features/blog/domain";
import {ApiCategoriesService, ApiPostsService} from "../features/blog/infrastructure";
import {FaqService} from "../features/faq/domain";
import {ApiFaqService} from "../features/faq/infrastructure";
import {PagesService} from "../features/pages/domain";
import {ApiPagesService} from "../features/pages/infrastructure";
import {GetCategoriesUseCase, GetPostsUseCase} from "../features/blog/application";
import {GetFaqListUseCase} from "../features/faq/application";
import {GetPageUseCase} from "../features/pages/application";
import {SendBusinessFormUseCase} from "../features/forms/application";
import {BusinessFormService} from "../features/forms/domain";
import {ApiBusinessFormService} from "../features/forms/infrastructure";
export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<CategoriesService>(contentTypes.CategoriesService).to(ApiCategoriesService);
  bind<PostsService>(contentTypes.PostsService).to(ApiPostsService);
  bind<FaqService>(contentTypes.FaqService).to(ApiFaqService);
  bind<PagesService>(contentTypes.PagesService).to(ApiPagesService);
  bind<BusinessFormService>(contentTypes.BusinessFormService).to(ApiBusinessFormService);

  bind<GetCategoriesUseCase>(contentTypes.GetCategoriesUseCase).to(GetCategoriesUseCase);
  bind<GetPostsUseCase>(contentTypes.GetPostsUseCase).to(GetPostsUseCase);
  bind<GetFaqListUseCase>(contentTypes.GetFaqListUseCase).to(GetFaqListUseCase);
  bind<GetPageUseCase>(contentTypes.GetPageUseCase).to(GetPageUseCase);
  bind<SendBusinessFormUseCase>(contentTypes.SendBusinessFormUseCase).to(SendBusinessFormUseCase);
});
