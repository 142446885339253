import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {ScheduleRepository} from "../domain";
import {Schedule} from "../../../models/schedule";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";

@injectable()
export class ApiScheduleRepository implements ScheduleRepository {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async fetchSchedule(serviceId: string): Promise<Result<Schedule, RuntimeError>> {
    return await this.httpService.get<Schedule>(`marketplace/service/${serviceId}/schedule`, {}, {});
  }
}
