import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {B2CCartService} from "../domain";
import {Cart, Schedule} from "../../../models/cart";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";

@injectable()
export class ApiB2CCartService implements B2CCartService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async createCart(serviceId: string, customerId: string, currency: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.post<Cart>('marketplace/carts/b2c', {
      serviceId, customerId, currency
    }, {});
  }

  async setService(cartId: string, serviceId: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.post<Cart>(`marketplace/carts/b2c/${cartId}/service`, {
      serviceId
    }, {});
  }

  async getCart(cartId: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.get<Cart>(`marketplace/carts/b2c/${cartId}`, {}, {})
  }

  async getSchedule(cartId: string): Promise<Result<Schedule, RuntimeError>> {
    return await this.httpService.get<Schedule>(`marketplace/carts/b2c/${cartId}/schedule`, {}, {})
  }

  async getPaymentMethods(cartId: string): Promise<Result<any[], RuntimeError>> {
    return await this.httpService.get<any[]>(`marketplace/carts/b2c/${cartId}/payment-methods`, {}, {})
  }

  async setPaymentMethod(cartId: string, paymentMethodId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<any[]>(`marketplace/carts/b2c/${cartId}/payment-methods`, {
      paymentMethodId,
    }, {})
  }
  async addToCart(cartId: string, timeSlot: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.put<Cart>(`marketplace/carts/b2c/${cartId}`, {
      timeSlot
    }, {})
  }

  async removeFromCart(cartId: string, cartItemId: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.delete<Cart>(`marketplace/carts/b2c/${cartId}/items/${cartItemId}`, {}, {})
  }

  async setPromoCode(cartId: string, promoCode: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`marketplace/carts/b2c/${cartId}/promoCode`, {code: promoCode}, {})
  }

  async checkout(cartId: string, timeZone: string, requestIds: string[]): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`marketplace/carts/b2c/${cartId}/checkout`, {
      timeZone, requestIds,
    }, {})
  }

  async pay(cartId: string): Promise<Result<{ invoiceId: string, paymentLink: string }, RuntimeError>> {
    return await this.httpService.post<{ orderId: string }>(`marketplace/carts/b2c/${cartId}/pay`, {}, {})
  }
}
