import mitt from 'mitt';
import {injectable} from "inversify";
import {EventBus} from "../eventBus";

@injectable()
export class MittEventBus implements EventBus{
    private readonly eventBus = mitt();

    emit(type: symbol, event: any) {
        this.eventBus.emit(type, event);
    }

    on(type: symbol, listener: (event: any) => void) {
        this.eventBus.on(type, () => listener(type));
    }

    off(type: symbol, listener: (event: any) => void) {
      this.eventBus.off(type, () => listener(type));
    }
}
