import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {cabinetPsychologistTypes} from "../../../di/types";
import {GeneralInfo} from "../../../models/profile";
import {PsychologistSuggestionService} from "../domain/psychologistSuggestionService";

@injectable()
export class ChatSuggestionsUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.PsychologistSuggestionService) private readonly psychologistSuggestionService: PsychologistSuggestionService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async create(chatRoomId: string, timeZone: string, timeSlots: string[], serviceId: string|null = null): Promise<GeneralInfo> {
    const response = await this.psychologistSuggestionService.create(
      chatRoomId, timeZone, timeSlots, serviceId
    );
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
