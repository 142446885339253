import {injectable, inject} from "inversify";
import {seoTypes} from "../../../di/types";
import {MetadataService} from "../domain";

@injectable()
export class GetMetadataUseCase {

  constructor(
    @inject(seoTypes.MetadataService) private readonly metadataService: MetadataService,
  ) { }

  async getAllMetadata(): Promise<any[]> {
    const response = await this.metadataService.getAllMetadata();
    if (response.isOk()) {
      return response.value;
    }
  }

}
