import {AnalyticsService} from "../domain";
import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {PlannedSessionsResponse} from "../../../models/analytics";

@injectable()
export class ApiAnalyticsService implements AnalyticsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getPlannedData(profileId: string): Promise<Result<PlannedSessionsResponse, RuntimeError>> {
    return await this.httpService.get<PlannedSessionsResponse>(`analytics/psychologist/${profileId}/finance/planned`, { }, {});
  }
}
