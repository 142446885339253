import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {contentTypes} from "../../../di/types";
import {PostsService} from "../domain";
import {PaginatedPostList} from "../../../models/blog";

@injectable()
export class GetPostsUseCase {
  constructor(
    @inject(contentTypes.PostsService) private readonly postService: PostsService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getAllPosts(page: number, pageSize: number = 9): Promise<PaginatedPostList> {
    const response = await this.postService.getAllPosts(page, pageSize);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getPostsByCategory(categoryId: string, page: number, pageSize: number = 9): Promise<PaginatedPostList> {
    const response = await this.postService.getPostsByCategory(categoryId, page, pageSize);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getPostBySlug(categoryId: string, postSlug: string): Promise<PaginatedPostList> {
    const response = await this.postService.getPostBySlug(categoryId, postSlug);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
