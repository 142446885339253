import {SettingsService} from "../domain";
import {injectable, inject} from "inversify";
import {Result} from "neverthrow";
import { httpTypes } from "@meclee/http/di/types";
import { HttpService } from "@meclee/http";
import {RuntimeError} from "@meclee/contracts";
import {IntegrationSettings} from "../../../models/settings";

@injectable()
export class ApiSettingsService implements SettingsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getIntegrationSettings(profileId: string): Promise<Result<IntegrationSettings, RuntimeError>> {
    return this.httpService.get<any>(`/id/settings/${profileId}/integrations`, {}, {});
  }

  async connectFacebook(profileId: string, fingerprint: string): Promise<Result<any, RuntimeError>> {
    return this.httpService.post<any>(`/id/settings/${profileId}/integrations/facebook`, {fingerprint}, {});
  }

  async disconnectFacebook(profileId: string): Promise<Result<any, RuntimeError>> {
    return this.httpService.delete<any>(`/id/settings/${profileId}/integrations/facebook`, {}, {});
  }

  async connectGoogle(profileId: string, fingerprint: string, withCalendar: boolean): Promise<Result<any, RuntimeError>> {
    return this.httpService.post<any>(`/id/settings/${profileId}/integrations/google`, {fingerprint, withCalendar}, {});
  }

  async disconnectGoogle(profileId: string, onlyCalendar: boolean): Promise<Result<any, RuntimeError>> {
    return this.httpService.delete<any>(`/id/settings/${profileId}/integrations/google`, {onlyCalendar}, {});
  }

  async connectTelegram(profileId: string, fingerprint: string): Promise<Result<any, RuntimeError>> {
    return this.httpService.post<any>(`/id/settings/${profileId}/integrations/telegram`, {fingerprint}, {});
  }

  async disconnectTelegram(profileId: string): Promise<Result<any, RuntimeError>> {
    return this.httpService.delete<any>(`/id/settings/${profileId}/integrations/telegram`, {}, {});
  }
}
