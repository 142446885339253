import {Container} from "inversify";

import httpContainer from '@meclee/http/di/nuxtContainer';
import notificationsContainer from '@meclee/notifications/di/nuxtContainer';
import i18nContainer from '@meclee/i18n/di/nuxtContainer';
import {i18nTypes} from '@meclee/i18n/di/types';
import authContainer from '@meclee/auth/di/nuxtContainer';
import validationContainer from '@meclee/validation/di/nuxtContainer';
import utilsContainer from '@meclee/utils/di/nuxtContainer';
import chatContainer from '@meclee/chat/di/nuxtClientContainer';
import marketplaceContainer from '@meclee/marketplace/di/nuxtContainer';
import eventBusContainer from '@meclee/eventbus/di/nuxtContainer';
import cabinetEmployeeContainer from '@meclee/cabinet-employee/di/nuxtContainer';
import cabinetHrContainer from '@meclee/cabinet-hr/di/nuxtContainer';
import realtimeContainer from '@meclee/realtime/di/nuxtClientContainer';
import financeContainer from '@meclee/finance/di/nuxtContainer';
import timeContainer from '@meclee/time/di/nuxtContainer';

export default defineNuxtPlugin((nuxtApp) => {
  const container = new Container();
  nuxtApp.callHook('inversify:init', {container});

  container.bind<string>(i18nTypes.I18nCurrentLocale).toConstantValue(nuxtApp.$i18n.locale.value);

  container.load(
    eventBusContainer,
    httpContainer, notificationsContainer,
    validationContainer, realtimeContainer,
    i18nContainer, authContainer, utilsContainer, chatContainer,
    cabinetHrContainer, cabinetEmployeeContainer,
    marketplaceContainer, financeContainer, timeContainer
  );

  nuxtApp.callHook('inversify:loaded', {container});

     return {
          provide: {
            container: container,
          }
     }
});
