import {inject, injectable} from "inversify";
import {OrderService} from "../domain";
import {FullSession, Order} from "../../../models/order";
import {RuntimeError} from "@meclee/contracts";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {HttpService} from "@meclee/http";

@injectable()
export class ApiOrderService implements OrderService {

  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getOrder(orderId: string): Promise<Result<Order, RuntimeError>> {
    return await this.httpService.get<Order>(`marketplace/orders/${orderId}`, {}, {});
  }

  async getSession(sessionId: string): Promise<Result<FullSession, RuntimeError>> {
    return await this.httpService.get<FullSession>(`marketplace/sessions/${sessionId}`, {}, {});
  }

  async confirmOrder(orderId: string): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`marketplace/orders/${orderId}/confirm`, {}, {});
  }

  async fetchCatalogItemForGTM(orderId: string): Promise<Result<{event: string, ecommerce: object}, RuntimeError>> {
    return await this.httpService.get<Order>(`marketplace/orders/${orderId}/gtm`, {}, {});
  }
}
