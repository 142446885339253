import {inject, injectable} from "inversify";
import {financeTypes} from "../../../di/types";
import {BalanceRepository} from "../ports";
import {CreditTransaction, PayoutTransaction} from "../../../entities/balance";

@injectable()
export class GetTransactions {
  constructor(
    @inject(financeTypes.BalanceRepository) private readonly balanceRepository: BalanceRepository,
  ) { }

  async execute(accountId: string, date: string, type: 'b2c'|'b2b'|null, status: 'finished'|'unfinished'|null): Promise<CreditTransaction[]|PayoutTransaction[]> {
    const response = await this.balanceRepository.getTransactions(
      accountId, date, type, status,
    );
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
