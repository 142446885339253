import {injectable, inject} from "inversify";
import {contentTypes} from "../../../di/types";
import {PagesService} from "../domain";
import {Page} from "../../../models/page";

@injectable()
export class GetPageUseCase {
  constructor(
    @inject(contentTypes.PagesService) private readonly pagesService: PagesService,
  ) { }

  async getPageBySlug(slug: string): Promise<Page> {
    const response = await this.pagesService.getPageBySlug(slug);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
