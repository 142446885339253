import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {AuthService} from "../domain";

@injectable()
export class LogoutUseCase {
  constructor(
    @inject(authTypes.AuthService) private readonly authService: AuthService,
  ) { }

  async execute(): Promise<void> {
    await this.authService.logout();
  }
}
