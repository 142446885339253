import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Result} from "neverthrow";
import 'reflect-metadata';
import {DateRange} from "../../../models/dateRange";
import {DashboardApiResponse} from "../../../models/dashboard";
import {DateRangeMode} from "../../../enums/dateRangeMode";
import {DashboardService} from "../domain";

@injectable()
export class ApiDashboardService implements DashboardService {
    constructor(
        @inject(httpTypes.HttpService) private readonly httpService: HttpService,
    ) { }

    async fetchDashboardByDateRange(companyId: string, dateRange: DateRange): Promise<Result<DashboardApiResponse, RuntimeError>> {
        let body = {};
        if (dateRange.getDateRangeMode() !== DateRangeMode.ALL) {
            body = {
                start_time: dateRange.getStartAsIsoString(),
                end_time: dateRange.getEndAsIsoString(),
            }
        }

        return await this.httpService.get<DashboardApiResponse>(`business/analytics/dashboard/${companyId}`, body, {});
    }

  async fetchSessionsByDateRange(companyId: string, dateRange: DateRange, page: number): Promise<Result<any[], RuntimeError>> {
    let body: any = {page};
    if (dateRange.getDateRangeMode() !== DateRangeMode.ALL) {
      body = {
        page: page,
        start_time: dateRange.getStartAsIsoString(),
        end_time: dateRange.getEndAsIsoString(),
      }
    }

    return await this.httpService.get<any[]>(`business/analytics/dashboard/${companyId}/sessions`, body, {});
  }
}
