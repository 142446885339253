import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {B2BCartService} from "../domain";
import {NotifierService} from "@meclee/notifications";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {B2BCart} from "../../../models/cart";

@injectable()
export class B2BCartUseCase {

  constructor(
    @inject(marketplaceTypes.B2BCartService) private readonly b2bCartService: B2BCartService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async createCart(psychologistId: string, customerId: string): Promise<B2BCart> {
    const response = await this.b2bCartService.createCart(psychologistId, customerId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async addToCart(cartId: string, timeSlot: string): Promise<B2BCart> {
    const response = await this.b2bCartService.addToCart(cartId, timeSlot);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async removeFromCart(cartId: string, cartItemId: string): Promise<B2BCart> {
    const response = await this.b2bCartService.removeFromCart(cartId, cartItemId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getCart(cartId: string): Promise<B2BCart> {
    const response = await this.b2bCartService.getCart(cartId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getSchedule(cartId: string) {
    const response = await this.b2bCartService.getSchedule(cartId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async checkout(cartId: string, timeZone: string, requestIds: string[]): Promise<void> {
    const response = await this.b2bCartService.checkout(cartId, timeZone, requestIds);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async pay(cartId: string): Promise<{ orderId: string }> {
    const response = await this.b2bCartService.pay(cartId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }
}
