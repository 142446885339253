import {inject, injectable} from "inversify";
import {eventBusTypes} from "@meclee/eventbus/di/types";
import {EventBus} from "@meclee/eventbus";
import {VideoTransportService, VideoTransportServiceFactory} from "../domain";
import {VideoProvider} from "../../../enums";
import {ZoomVideoTransportService} from "./zoomVideoTransportService";
import {TwilioVideoTransportService} from "./twilioVideoTransportService";

@injectable()
export class NuxtVideoTransportServiceFactory implements VideoTransportServiceFactory {

  constructor(
    @inject(eventBusTypes.EventBus) private readonly eventBus: EventBus,
  )  { }

  create(provider: VideoProvider): VideoTransportService {
    if (provider === VideoProvider.Twilio) {
      return new TwilioVideoTransportService(this.eventBus);
    } else if (provider === VideoProvider.Zoom) {
      return new ZoomVideoTransportService(this.eventBus);
    }
  }
}
