import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {B2CCartService} from "../domain";
import {NotifierService} from "@meclee/notifications";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {B2BCart} from "../../../models/cart";

@injectable()
export class B2CCartUseCase {

  constructor(
    @inject(marketplaceTypes.B2CCartService) private readonly b2cCartService: B2CCartService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async createCart(serviceId: string, customerId: string, currency: string = 'UAH'): Promise<B2BCart> {
    const response = await this.b2cCartService.createCart(serviceId, customerId, currency);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async setService(cartId: string, serviceId: string): Promise<B2BCart> {
    const response = await this.b2cCartService.setService(cartId, serviceId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async addToCart(cartId: string, timeSlot: string): Promise<B2BCart> {
    const response = await this.b2cCartService.addToCart(cartId, timeSlot);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async removeFromCart(cartId: string, cartItemId: string): Promise<B2BCart> {
    const response = await this.b2cCartService.removeFromCart(cartId, cartItemId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getCart(cartId: string): Promise<B2BCart> {
    const response = await this.b2cCartService.getCart(cartId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getSchedule(cartId: string) {
    const response = await this.b2cCartService.getSchedule(cartId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getPaymentMethods(cartId: string) {
    const response = await this.b2cCartService.getPaymentMethods(cartId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async setPaymentMethod(cartId: string, paymentMethodId: string) {
    const response = await this.b2cCartService.setPaymentMethod(cartId, paymentMethodId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async setPromoCode(cartId: string, promoCode: string) {
    const response = await this.b2cCartService.setPromoCode(cartId, promoCode);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);

      throw response.error;
    }
  }

  async checkout(cartId: string, timeZone: string, requestIds: string[]): Promise<void> {
    const response = await this.b2cCartService.checkout(cartId, timeZone, requestIds);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async pay(cartId: string): Promise<{ invoiceId: string, paymentLink: string }> {
    const response = await this.b2cCartService.pay(cartId);
    if (response.isOk()) {
      return response.value;
    }
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
