export const marketplaceTypes = {
  CatalogRepository: Symbol.for('MarketplaceCatalogRepository'),
  B2BCartService: Symbol.for('MarketplaceB2BCartService'),
  B2CCartService: Symbol.for('MarketplaceB2CCartService'),
  OrderService: Symbol.for('MarketplaceOrderService'),
  SessionService: Symbol.for('MarketplaceSessionService'),
  RescheduleService: Symbol.for('MarketplaceRescheduleService'),
  CartRepository: Symbol.for('MarketplaceCartRepository'),
  ScheduleRepository: Symbol.for('MarketplaceScheduleRepository'),
  MatchingService: Symbol.for('MarketplaceMatchingService'),

  GetSchedule: Symbol.for('MarketplaceGetSchedule'),
  FilterCatalog: Symbol.for('MarketplaceFilterCatalog'),
  GetCatalogItem: Symbol.for('MarketplaceGetCatalogItem'),

  B2BCartUseCase: Symbol.for('MarketplaceB2BCartUseCase'),
  B2CCartUseCase: Symbol.for('MarketplaceB2CCartUseCase'),
  GetOrderUseCase: Symbol.for('MarketplaceGetOrderUseCase'),
  ConfirmOrderUseCase: Symbol.for('MarketplaceConfirmOrderUseCase'),
  GetSessionUseCase: Symbol.for('MarketplaceGetSessionUseCase'),
  CancelSessionUseCase: Symbol.for('MarketplaceCancelSessionUseCase'),
  RescheduleUseCase: Symbol.for('MarketplaceRescheduleUseCase'),
  MatchingUseCase: Symbol.for('MarketplaceMatchingUseCase'),

  CreateCart: Symbol.for('MarketplaceCreateCart'),
  GetCart: Symbol.for('MarketplaceGetCart'),
  AddToCart: Symbol.for('MarketplaceAddToCart'),
  RemoveFromCart: Symbol.for('MarketplaceRemoveFromCart'),
  CheckoutCart: Symbol.for('MarketplaceCheckoutCart'),

  CustomerReviewUseCase: Symbol.for('MarketplaceCustomerReviewUseCase'),
  ReviewsService: Symbol.for('MarketplaceReviewsService'),

  GetMyPsychologistsUseCase: Symbol.for('MarketplaceGetMyPsychologistsUseCase'),
  MyPsychologistsService: Symbol.for('MarketplaceMyPsychologistsService'),

  SendMessageUseCase: Symbol.for('MarketplaceSendMessageUseCase'),
  PsychologistMessagesService: Symbol.for('MarketplacePsychologistMessagesService'),

  GoalsUseCase: Symbol.for('MarketplaceGoalsUseCase'),
  GoalsService: Symbol.for('MarketplaceGoalsService'),
}
