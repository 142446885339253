import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {CatalogRepository} from "../domain";
import {Catalog, CatalogItem} from "../../../models/catalog";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";

@injectable()
export class ApiCatalogRepository implements CatalogRepository {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async fetchCatalog(params: Record<string, string|string[]> = {}): Promise<Result<Catalog, RuntimeError>> {
    return await this.httpService.get<Catalog>('marketplace/catalog-search', params, {})
  }

  async fetchCatalogItem(catalogItemId: string): Promise<Result<CatalogItem, RuntimeError>> {
    return await this.httpService.get<CatalogItem>(`marketplace/catalog-search/${catalogItemId}`, {}, {});
  }

  async fetchCatalogItemForGTM(catalogItemId: string): Promise<Result<object, RuntimeError>> {
    return await this.httpService.get<object>(`marketplace/catalog-search/${catalogItemId}/gtm`, {}, {});
  }
}
