export const cabinetPsychologistTypes = {
  ProfileRepository: Symbol.for("CabinetPsychologistProfileRepository"),
  AccountService: Symbol.for("CabinetPsychologistAccountService"),
  CardsService: Symbol.for("CabinetPsychologistCardsService"),
  FondyService: Symbol.for("CabinetPsychologistFondyService"),

  GetProfileUseCase: Symbol.for("CabinetPsychologistGetProfileUseCase"),
  UpdateProfileUseCase: Symbol.for("CabinetPsychologistUpdateProfileUseCase"),
  TopUpBalanceUseCase: Symbol.for("CabinetPsychologistTopUpBalanceUseCase"),
  BalanceUseCase: Symbol.for("CabinetPsychologistBalanceUseCase"),
  CardsUseCase: Symbol.for("CabinetPsychologistCardsUseCase"),

  GetScheduleUseCase: Symbol.for("CabinetPsychologistGetScheduleUseCase"),
  GetAnalyticsUseCase: Symbol.for("CabinetPsychologistGetAnalyticsUseCase"),
  AnalyticsService: Symbol.for("CabinetPsychologistAnalyticsService"),
  CalendarService: Symbol.for("CabinetPsychologistCalendarService"),
  EventUseCase: Symbol.for("EventUseCase"),

  GoogleCalendarsUseCase: Symbol.for("CabinetPsychologistGoogleCalendarsUseCase"),
  GoogleCalendarService: Symbol.for("CabinetPsychologistGoogleCalendarService"),

  MyCustomersListUseCase: Symbol.for("CabinetPsychologistMyCustomersListUseCase"),
  MyCustomersService: Symbol.for("CabinetPsychologistMyCustomersService"),

  ChatSuggestionsUseCase: Symbol.for("CabinetPsychologistChatSuggestionsUseCase"),
  PsychologistSuggestionService: Symbol.for("CabinetPsychologistMyPsychologistSuggestionService"),

  GetPaymentMethodsUseCase: Symbol.for("CabinetPsychologistGetPaymentMethodsUseCase"),
  DeletePaymentMethodsUseCase: Symbol.for("CabinetPsychologistDeletePaymentMethodsUseCase"),
};
