import {inject, injectable} from "inversify";
import {Result, ok, err} from "neverthrow";
import {PaymentMethodService} from "../domain";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {WayforpayPaymentMethod} from "../../../models/payments";

@injectable()
export class ApiPaymentMethodService implements PaymentMethodService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async createWayforpayMethod(entity: WayforpayPaymentMethod): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<any>(`finance/payment-method`, entity, {});
  }
}
