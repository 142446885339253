import {inject, injectable} from "inversify";
import {HttpService} from '@meclee/http';
import { httpTypes } from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {BusinessFormService} from "../domain";
import {B2BLeadForm} from "../../../models/forms";

@injectable()
export class ApiBusinessFormService implements BusinessFormService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async sendForm(formData: B2BLeadForm): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`business/connect`, formData, {});
  }
}
