import {inject, injectable} from "inversify";
import {HttpService} from '@meclee/http';
import { httpTypes } from "@meclee/http/di/types";
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {PagesService} from "../domain";
import {Page} from "../../../models/page";

@injectable()
export class ApiPagesService implements PagesService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getPageBySlug(slug: string): Promise<Result<Page, RuntimeError>> {
    return await this.httpService.get<Page>(`content/pages/${slug}`, {}, {});
  }
}
