import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {NotifierService} from "@meclee/notifications";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {ReviewsService} from "../domain";
import {CustomerReview} from "../../../models/reviews";

@injectable()
export class CustomerReviewUseCase {
  constructor(
    @inject(marketplaceTypes.ReviewsService) private readonly reviewsService: ReviewsService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async sendReview(sessionId: string, review: CustomerReview): Promise<void> {
    const response = await this.reviewsService.sendReview(sessionId, review);
    if (response.isOk()) {
      this.notifierService.createSuccessNotification('marketplace.review.reviewSent', {sessionId});
    }

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async isSessionHasReview(sessionId: string): Promise<boolean> {
    const response = await this.reviewsService.isSessionHasReview(sessionId);
    if (response.isOk()) {
      return response.value.ok;
    }

    return true;
  }
}
