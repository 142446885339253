import mitt from 'mitt'

export default defineNuxtPlugin({
  enforce: 'pre',
  setup() {
    const emitter = mitt()
    return {
      provide: {
        event: emitter.emit,
        listen: emitter.on,
      }
    }
  }
})
