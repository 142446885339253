import {AsyncContainerModule, interfaces} from "inversify";
import {cabinetHrTypes} from "./types";
import {DashboardService, ReportService} from "../features/dashboard/domain";
import {ApiDashboardService} from "../features/dashboard/infrastructure";
import {GetDashboardUseCase} from "../features/dashboard/application";
import {ApiReportService} from "../features/dashboard/infrastructure/apiReportService";
import {DownloadReportUseCase} from "../features/dashboard/application/downloadReportUseCase";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<DashboardService>(cabinetHrTypes.DashboardService).to(ApiDashboardService);
  bind<ReportService>(cabinetHrTypes.ReportService).to(ApiReportService);

  bind<GetDashboardUseCase>(cabinetHrTypes.GetDashboardUseCase).to(GetDashboardUseCase);
  bind<DownloadReportUseCase>(cabinetHrTypes.DownloadReportUseCase).to(DownloadReportUseCase);
});
