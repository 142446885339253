import {injectable, inject} from "inversify";
import {financeTypes} from "../../../di/types";
import {NotifierService} from "@meclee/notifications";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {PaymentMethodService} from "../domain";
import {WayforpayPaymentMethod} from "../../../models/payments";

@injectable()
export class CreatePaymentMethodUseCase {
  constructor(
    @inject(financeTypes.PaymentMethodService) private readonly paymentMethodService: PaymentMethodService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async createWayforpayMethod(paymentMethod: WayforpayPaymentMethod): Promise<any> {
    const response = await this.paymentMethodService.createWayforpayMethod(paymentMethod);
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
