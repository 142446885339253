import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {ProfileRegistrationResponse} from "../../../models/registration";
import {CustomerRegistrationService} from "../../registration";

@injectable()
export class AddCustomerProfileUseCase {
  constructor(
    @inject(authTypes.CustomerRegistrationService) private readonly customerRegistrationService: CustomerRegistrationService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async execute(fingerprint: string): Promise<ProfileRegistrationResponse> {
    const response = await this.customerRegistrationService.createProfile(fingerprint);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
