import {AsyncContainerModule, interfaces} from "inversify";
import {timeTypes} from "./types";
import {CheckTimeUseCase} from "../features/checktime/application/checkTimeUseCase";
import {TimeApiService} from "../features/checktime/domain/timeApiService";
import {ApiWorldTimeService} from "../features/checktime/infrastructure/apiWorldTimeService";

export default new AsyncContainerModule(async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
  bind<CheckTimeUseCase>(timeTypes.CheckTimeUseCase).to(CheckTimeUseCase);
  bind<TimeApiService>(timeTypes.TimeApiService).to(ApiWorldTimeService);
});
