import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {inject, injectable} from "inversify";
import {chatTypes} from "../../../di/types";
import {CallUpResponse, Meeting} from "../../../models/video";
import {MeetingService} from "../domain";

@injectable()
export class MeetingUseCase {
  constructor(
    @inject(chatTypes.MeetingService) private readonly meetingService: MeetingService,
  ) { }

  async getMeeting(meetingId: string): Promise<Result<Meeting, RuntimeError>> {
    const response = await this.meetingService.getMeeting(meetingId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }

  async callUp(meetingId: string): Promise<CallUpResponse> {
    const response = await this.meetingService.callUp(meetingId);
    if (response.isOk()) {
      return response.value;
    } else {
      throw response.error;
    }
  }
}
