import {injectable, inject} from "inversify";
import {cabinetPsychologistTypes} from "../../../di/types";
import {AccountService} from "../domain";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";

@injectable()
export class TopUpUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.AccountService) private readonly accountService: AccountService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async execute(accountId: string, cardId: string, amount: number): Promise<string|undefined> {
    const topUpResponse = await this.accountService.topUpBalance(accountId, cardId, amount);
    if (topUpResponse.isOk()) {
      return topUpResponse.value?.paymentLink;
    } else {
      this.notifierService.notifyFromHttpError(topUpResponse.error);
      throw topUpResponse.error;
    }
  }
}
