import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {marketplaceTypes} from "../../../di/types";
import {PsychologistMessage} from "../../../models/messages";
import {PsychologistMessagesService} from "../domain";

@injectable()
export class SendMessageUseCase {
  constructor(
    @inject(marketplaceTypes.PsychologistMessagesService) private readonly psychologistMessagesService: PsychologistMessagesService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async send(psychologistSlug: string, formData: PsychologistMessage): Promise<{ roomId: string }> {
    const response = await this.psychologistMessagesService.sendForm(psychologistSlug, formData);

    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
    if (response.isOk()) {
      return response.value;
    }
  }
}
