import { AsyncContainerModule, interfaces } from "inversify";
import { cabinetPsychologistTypes } from "./types";
import {
  AccountService,
  AnalyticsService,
  CardsService,
  FondyService,
} from "../features/balance/domain";
import {
  ApiAccountService,
  ApiAnalyticsService,
  ApiCardsService,
  FakeFondyService,
} from "../features/balance/infrastructure";
import {
  BalanceUseCase,
  CardsUseCase,
  TopUpUseCase,
} from "../features/balance/application";
import { ProfileRepository } from "../features/profile/domain";
import { ApiProfileRepository } from "../features/profile/infrastructure";
import {
  GetProfileUseCase,
  UpdateProfileUseCase,
} from "../features/profile/application";
import {
  GetScheduleUseCase,
  EventUseCase,
} from "../features/schedule/application";
import { CalendarService } from "../features/schedule/domain";
import { ApiCalendarService } from "../features/schedule/infrastructure";
import {GoogleCalendarsUseCase} from "../features/google-calendar/application";
import {GoogleCalendarService} from "../features/google-calendar/domain";
import {ApiGoogleCalendarService} from "../features/google-calendar/infrastructure";
import {AnalyticsUseCase} from "../features/balance/application/analyticsUseCase";
import {MyCustomersService} from "../features/my-customers/domain";
import {MyCustomersListUseCase} from "../features/my-customers/application";
import {ApiMyCustomersService} from "../features/my-customers/infrastructure";
import {ChatSuggestionsUseCase} from "../features/suggestions/application/chatSuggestionsUseCase";
import {PsychologistSuggestionService} from "../features/suggestions/domain/psychologistSuggestionService";
import {
  ApiPsychologistSuggestionService
} from "../features/suggestions/infrastructure/apiPsychologistSuggestionService";
import {GetPaymentMethodsUseCase} from "../features/balance/application/getPaymentMethodsUseCase";
import {DeletePaymentMethodsUseCase} from "../features/balance/application/deletePaymentMethodsUseCase";

export default new AsyncContainerModule(
  async (bind: interfaces.Bind, unbind: interfaces.Unbind) => {
    bind<ProfileRepository>(cabinetPsychologistTypes.ProfileRepository).to(
      ApiProfileRepository
    );
    bind<AccountService>(cabinetPsychologistTypes.AccountService).to(
      ApiAccountService
    );
    bind<CardsService>(cabinetPsychologistTypes.CardsService).to(
      ApiCardsService
    );

    if (process.client) {
      const { JsFondyService } = await import(
        "../features/balance/infrastructure/jsFondyService"
      );
      bind<FondyService>(cabinetPsychologistTypes.FondyService).to(
        JsFondyService
      );
    } else {
      bind<FondyService>(cabinetPsychologistTypes.FondyService).to(
        FakeFondyService
      );
    }

    bind<GetPaymentMethodsUseCase>(cabinetPsychologistTypes.GetPaymentMethodsUseCase).to(
      GetPaymentMethodsUseCase
    );
    bind<DeletePaymentMethodsUseCase>(cabinetPsychologistTypes.DeletePaymentMethodsUseCase).to(
      DeletePaymentMethodsUseCase
    );
    bind<TopUpUseCase>(cabinetPsychologistTypes.TopUpBalanceUseCase).to(
      TopUpUseCase
    );
    bind<BalanceUseCase>(cabinetPsychologistTypes.BalanceUseCase).to(
      BalanceUseCase
    );
    bind<CardsUseCase>(cabinetPsychologistTypes.CardsUseCase).to(CardsUseCase);
    bind<GetProfileUseCase>(cabinetPsychologistTypes.GetProfileUseCase).to(
      GetProfileUseCase
    );
    bind<UpdateProfileUseCase>(
      cabinetPsychologistTypes.UpdateProfileUseCase
    ).to(UpdateProfileUseCase);

    bind<GetScheduleUseCase>(cabinetPsychologistTypes.GetScheduleUseCase).to(
      GetScheduleUseCase
    );
    bind<CalendarService>(cabinetPsychologistTypes.CalendarService).to(
      ApiCalendarService
    );
    bind<EventUseCase>(cabinetPsychologistTypes.EventUseCase).to(EventUseCase);

    bind<GoogleCalendarsUseCase>(cabinetPsychologistTypes.GoogleCalendarsUseCase).to(GoogleCalendarsUseCase);
    bind<GoogleCalendarService>(cabinetPsychologistTypes.GoogleCalendarService).to(ApiGoogleCalendarService);

    bind<AnalyticsUseCase>(cabinetPsychologistTypes.GetAnalyticsUseCase).to(AnalyticsUseCase);
    bind<AnalyticsService>(cabinetPsychologistTypes.AnalyticsService).to(ApiAnalyticsService);

    bind<MyCustomersListUseCase>(cabinetPsychologistTypes.MyCustomersListUseCase).to(MyCustomersListUseCase);
    bind<MyCustomersService>(cabinetPsychologistTypes.MyCustomersService).to(ApiMyCustomersService);

    bind<ChatSuggestionsUseCase>(cabinetPsychologistTypes.ChatSuggestionsUseCase).to(ChatSuggestionsUseCase);
    bind<PsychologistSuggestionService>(cabinetPsychologistTypes.PsychologistSuggestionService).to(ApiPsychologistSuggestionService);
  }
);
