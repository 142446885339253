import {cabinetPsychologistTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {injectable, inject} from "inversify";
import {GoogleCalendarService, MyCustomersService} from "../domain";

@injectable()
export class MyCustomersListUseCase {
  constructor(
    @inject(cabinetPsychologistTypes.MyCustomersService) private readonly myCustomersService: MyCustomersService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getList(profileId: string, page: number): Promise<any[]> {
    const response = await this.myCustomersService.getList(profileId, page);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async getSessionsByCustomer(profileId: string, customerId: string): Promise<any[]> {
    const response = await this.myCustomersService.getSessions(profileId, customerId);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
