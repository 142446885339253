import {HttpService} from "@meclee/http";
import {httpTypes} from "@meclee/http/di/types";
import {injectable, inject} from "inversify";
import {GoalsService} from "../domain/goalsService";
import {Result} from "neverthrow";
import {RuntimeError} from "@meclee/contracts";
import {
  CustomerGoalGroupHistoryItemResponse,
  CustomerGoalReportsResponse,
  CustomerGoalsResponse,
  CustomerGoalsResponseItem,
  CustomerWellbeingResponse
} from "../../../models/goals";

@injectable()
export class ApiGoalsService implements GoalsService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }


}
