import {injectable, inject} from "inversify";
import {NotifierService} from "@meclee/notifications";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {financeTypes} from "../../../di/types";
import {InvoiceService} from "../domain";
import {Invoice} from "../../../models/invoice";

@injectable()
export class GetInvoiceUseCase {
  constructor(
    @inject(financeTypes.InvoiceService) private readonly invoiceService: InvoiceService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getById(id: string): Promise<Invoice> {
    const invoiceResponse = await this.invoiceService.getOneById(id);
    if (invoiceResponse.isOk()) {
      return invoiceResponse.value;
    }
    if (invoiceResponse.isErr()) {
      this.notifierService.notifyFromHttpError(invoiceResponse.error);
      throw invoiceResponse.error;
    }
  }
}
