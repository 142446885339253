import {inject, injectable} from "inversify";
import {SessionService} from "../domain";
import {NearestSession, PaginatedSessionList} from "../../../models/order";
import {RuntimeError} from "@meclee/contracts";
import {Result} from "neverthrow";
import {httpTypes} from '@meclee/http/di/types';
import {HttpService} from "@meclee/http";

@injectable()
export class ApiSessionService implements SessionService {

  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async getSessionsByCustomerId(customerId:string, page: number): Promise<Result<PaginatedSessionList, RuntimeError>> {
    return await this.httpService.get<PaginatedSessionList>(`marketplace/sessions?page=${page}&customer=${customerId}`, {}, {});
  }

  async getNearestSessionByProfileId(profileId:string): Promise<Result<NearestSession, RuntimeError>> {
    return await this.httpService.get<NearestSession>(`id/nearest-sessions/${profileId}`, {}, {});
  }

  async cancel(sessionId: string, withRefund: boolean): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`marketplace/sessions/${sessionId}/cancel`, {
      withRefund,
    }, {});
  }
}
