import {injectable, inject} from "inversify";
import {authTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {ProfileRegistrationResponse} from "../../../models/registration";
import {EmployeeRegistrationService} from "../../registration";

@injectable()
export class AddEmployeeProfileUseCase {
  constructor(
    @inject(authTypes.EmployeeRegistrationService) private readonly employeeRegistrationService: EmployeeRegistrationService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async createByEmail(corporateEmail: string, problemDescription: string, requests: string[]): Promise<ProfileRegistrationResponse> {
    const response = await this.employeeRegistrationService.createByCorporateEmail(
      corporateEmail, problemDescription, requests
    );
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async createByInviteCode(fingerprint: string, inviteCode: string, problemDescription: string, requests: string[]): Promise<ProfileRegistrationResponse> {
    const response = await this.employeeRegistrationService.createByInviteCode(
      fingerprint, inviteCode, problemDescription, requests
    );
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async changeCorporateEmail(profileId: string, corporateEmail: string): Promise<ProfileRegistrationResponse> {
    const response = await this.employeeRegistrationService.changeEmail(profileId, corporateEmail);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async confirmEmail(profileId: string, code: string, fingerprint: string): Promise<ProfileRegistrationResponse> {
    const response = await this.employeeRegistrationService.confirmEmailByProfileId(profileId, code, fingerprint);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }

  async resendCode(profileId: string): Promise<void> {
    const response = await this.employeeRegistrationService.resendEmailByProfileId(profileId);
    if (response.isOk()) {
      return response.value;
    } else {
      this.notifierService.notifyFromHttpError(response.error);
      throw response.error;
    }
  }
}
