import {inject, injectable} from "inversify";
import {Result} from "neverthrow";
import {B2BCartService} from "../domain";
import {Cart, Schedule} from "../../../models/cart";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";

@injectable()
export class ApiB2BCartService implements B2BCartService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async createCart(psychologistId: string, customerId: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.post<Cart>('marketplace/carts/b2b', {
      psychologistId, customerId,
    }, {});
  }

  async getCart(cartId: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.get<Cart>(`marketplace/carts/b2b/${cartId}`, {}, {})
  }

  async getSchedule(cartId: string): Promise<Result<Schedule, RuntimeError>> {
    return await this.httpService.get<Schedule>(`marketplace/carts/b2b/${cartId}/schedule`, {}, {})
  }

  async addToCart(cartId: string, timeSlot: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.put<Cart>(`marketplace/carts/b2b/${cartId}`, {
      timeSlot
    }, {})
  }

  async removeFromCart(cartId: string, cartItemId: string): Promise<Result<Cart, RuntimeError>> {
    return await this.httpService.delete<Cart>(`marketplace/carts/b2b/${cartId}/items/${cartItemId}`, {}, {})
  }

  async checkout(cartId: string, timeZone: string, requestIds: string[]): Promise<Result<void, RuntimeError>> {
    return await this.httpService.post<void>(`marketplace/carts/b2b/${cartId}/checkout`, {
      timeZone, requestIds,
    }, {})
  }

  async pay(cartId: string): Promise<Result<{ orderId: string }, RuntimeError>> {
    return await this.httpService.post<{ orderId: string }>(`marketplace/carts/b2b/${cartId}/pay`, {}, {})
  }
}
