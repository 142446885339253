import {inject, injectable} from "inversify";
import {Result, ok, err} from "neverthrow";
import {MatchingService} from "../domain";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Workflow} from "../../../models/workflow";

@injectable()
export class ApiMatchingService implements MatchingService {
  constructor(
    @inject(httpTypes.HttpService) private readonly httpService: HttpService,
  ) { }

  async createWorkflow(version: string, timeZone: string, fingerprint: string): Promise<Result<Workflow, RuntimeError>> {
    return await this.httpService.post<any>(`marketplace/matching/workflow`, {version, timezone: timeZone, fingerprint}, {});
  }

  async commitWorkflow(workflowId: string, transition: string, data: any): Promise<Result<Workflow, RuntimeError>> {
    return await this.httpService.put<any>(`marketplace/matching/workflow/${workflowId}`, {
      transition,
      data,
    }, {});
  }

  async getCountPsychologists(workflowId: string, transition: string, data: any): Promise<Result<number, RuntimeError>> {
    const response = await this.httpService.post<{ count: number }>(`marketplace/matching/workflow/${workflowId}/count`, {
      transition,
      data,
    }, {});
    if (response.isOk()) {
      return ok(response.value.count);
    }
    if (response.isErr()) {
      return err(response.error);
    }
  }

  async getCategories(workflowId: string): Promise<Result<any, RuntimeError>> {
    return await this.httpService.get<any>(`marketplace/matching/workflow/${workflowId}/categories`, {}, {});
  }

  async getRequests(workflowId: string): Promise<Result<any, RuntimeError>> {
    return await this.httpService.get<any>(`marketplace/matching/workflow/${workflowId}/requests`, {}, {});
  }
}
