export const financeTypes = {
  PayoutMethodRepository: Symbol.for('FinancePayoutMethodRepository'),
  BalanceRepository: Symbol.for('FinanceBalanceRepository'),
  PayoutRequestRepository: Symbol.for('FinancePayoutRequestRepository'),

  CreatePayoutMethod: Symbol.for('FinanceCreatePayoutMethod'),
  GetPayoutMethod: Symbol.for('FinanceGetPayoutMethod'),
  UpdatePayoutMethod: Symbol.for('FinanceUpdatePayoutMethod'),
  DeletePayoutMethod: Symbol.for('FinanceDeletePayoutMethod'),

  CreatePaymentMethod: Symbol.for('FinanceCreatePaymentMethod'),
  PaymentMethodService: Symbol.for('FinancePaymentMethodService'),

  GetBalances: Symbol.for('FinanceGetBalances'),
  GetTransactions: Symbol.for('FinanceGetTransactions'),
  CreatePayoutRequest: Symbol.for('FinanceCreatePayoutRequest'),
  GetPayoutRequests: Symbol.for('FinanceGetPayoutRequests'),

  InvoiceService: Symbol.for('FinanceInvoiceService'),
  GetInvoiceUseCase: Symbol.for('FinanceGetInvoiceUseCase'),
}
