import {injectable} from "inversify";
import {SessionRepository} from "../domain";
import {Session} from "../../../models/session";
import {ofetch} from "ofetch";

@injectable()
export class ApiSessionRepository implements SessionRepository {
  async getSession(): Promise<Session|undefined> {
    try {
      return await ofetch<Session>('/api/auth/getSession');
    } catch (e) {
      if (e.statusCode === 401) {
        throw e;
      }
      return undefined;
    }
  }
}
