import {enforce} from "vest";
import isStrongPassword from 'validator/es/lib/isStrongPassword';

enforce.extend({ isStrongPassword });

export function password(value: any) {
  enforce(value).message('validator.password').isStrongPassword({
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 0,
    minSymbols: 0,
  });
}
