import {injectable, inject} from "inversify";
import {marketplaceTypes} from "../../../di/types";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {FullSession, Order} from "../../../models/order";
import {OrderService} from "../domain";

@injectable()
export class GetOrderUseCase {

  constructor(
    @inject(marketplaceTypes.OrderService) private readonly orderService: OrderService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async getOrder(orderId: string): Promise<Order> {
    const response = await this.orderService.getOrder(orderId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getSession(sessionId: string): Promise<FullSession> {
    const response = await this.orderService.getSession(sessionId);
    if (response.isOk()) {
      return response.value;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getAllOrdersByProfileId(profileId: string): Promise<Order[]> {
    const response = await this.orderService.getOrdersByProfileId(profileId);
    if (response.isOk()) {
      return response.value.orders;
    } else {
      if (response.isErr()) {
        this.notifierService.notifyFromHttpError(response.error);
      }

      throw response.error;
    }
  }

  async getGtmEventData(orderId: string): Promise<{event: string, ecommerce: object}> {
    const response = await this.orderService.fetchCatalogItemForGTM(orderId);
    if (response.isOk()) {
      return response.value;
    }
    if (response.isErr()) {
      throw response.error;
    }
  }

}
