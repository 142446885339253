export const contentTypes = {
  CategoriesService: Symbol.for('ContentBlogCategoriesService'),
  PostsService: Symbol.for('ContentBlogPostsService'),
  FaqService: Symbol.for('ContentFaqFaqService'),
  PagesService: Symbol.for('ContentPagesPagesService'),
  BusinessFormService: Symbol.for('ContentFormsBusinessFormService'),

  GetCategoriesUseCase: Symbol.for('ContentBlogGetCategoriesUseCase'),
  GetPostsUseCase: Symbol.for('ContentBlogGetPostsUseCase'),
  GetFaqListUseCase: Symbol.for('ContentFaqGetFaqListUseCase'),
  GetPageUseCase: Symbol.for('ContentPagesGetPageUseCase'),
  SendBusinessFormUseCase: Symbol.for('ContentFormsSendBusinessFormUseCase'),
}
